<template>
    <div class="stats-bits-mobile d-flex align-items-center justify-content-center">
        <div class="info d-flex align-items-center profile-tooltip-open mx-2"
             @click="tooltipStore.setProfileTooltip(true)">
            <div class="profile-image">
                <img v-if="avatarImg"
                     :alt="getTrans('quests-dashboard.avatarImg', 'Avatar image')"
                     :title="getTrans('quests-dashboard.avatarImg', 'Avatar image')"
                     :src="avatarImg">
                <i class="fa-solid fa-caret-down" />
            </div>
        </div>
        <div>
            <div class="stats-title">
                <Translate translationKey="quests-dashboard.degree"
                           fallback="Degree" />
            </div>
            <div class="font-weight-bold stat-number text-center stat-number--fixed-height">
                <div v-if="userStore.isLoading"
                     class="loader-container">
                    <Loader class="mr-1"
                            :size="16"
                            :variant="LoaderVariantsEnum.WHITE" />
                </div>
                <template v-else>
                    <span v-if="userStore.degree?.current_degree"
                          class="stats-number">
                        <span>{{ userStore.degree.current_degree }}</span>
                        <span class="degree-symbol ml-1">°</span>
                    </span>
                    <span v-else
                          class="stats-number">0<span class="degree-symbol">°</span></span>
                </template>
            </div>
        </div>
    </div>
    <TippyMobile v-if="navigationStore.showMobileProfile"
                 name="profile">
        <div class="profile-tooltip mobile">
            <RouterLink :to="{ name: dashboardRoutesNames.WALLET_ROUTE_NAME }"
                        @click="tooltipStore.setProfileTooltip(false)">
                <div class="profile-tooltip-item profile-top-item-padding d-flex align-items-center">
                    <i class="fa-brands fa-bitcoin pr-2" />
                    <div>
                        <Translate translationKey="quests-dashboard.wallet"
                                   fallback="Wallet" />
                        <div v-if="authStore.user?.email"
                             class="profile-email-address">
                            {{ authStore.user?.email }}
                        </div>
                    </div>
                </div>
            </RouterLink>
            <RouterLink :to="{ name: dashboardRoutesNames.FRIENDS_ROUTE_NAME }"
                        @click="tooltipStore.setProfileTooltip(false)">
                <div class="profile-tooltip-item d-flex align-items-center">
                    <div class="pr-2">
                        <img width="16"
                             :src="asset_cdn('assets/missions/coin.svg')"
                             :alt="getTrans('quests-dashboard.inviteToEarnRewards', 'Invite to Earn Rewards')"
                             :title="getTrans('quests-dashboard.inviteToEarnRewards', 'Invite to Earn Rewards')">
                    </div>
                    <Translate translationKey="quests-dashboard.inviteToEarnRewards"
                               fallback="Invite to Earn Rewards" />
                </div>
            </RouterLink>
            <RouterLink :to="{ name: dashboardRoutesNames.GUILDS_STUDIO_ROUTE_NAME }"
                        @click="tooltipStore.setProfileTooltip(false)">
                <div class="profile-tooltip-item d-flex align-items-center">
                    <i class="fa-solid fa-helmet-battle pr-2 " />
                    Guilds Studio
                </div>
            </RouterLink>
            <a :href="route('cryptocurrencies-index')"
               target="_blank">
                <div class="profile-tooltip-item d-flex align-items-center">
                    <i class="fa-solid fa-coins pr-2" />
                    <Translate translationKey="quests-dashboard.cryptoHub"
                               fallback="Crypto Hub" />
                </div>
            </a>
            <RouterLink :to="{ name: dashboardRoutesNames.SETTINGS_ROUTE_NAME }"
                        @click="tooltipStore.setProfileTooltip(false)">
                <div class="profile-tooltip-item d-flex align-items-center">
                    <i class="fa-solid fa-gear pr-2" />
                    <Translate translationKey="quests-dashboard.settings"
                               fallback="Settings" />
                </div>
            </RouterLink>
            <div class="d-flex justify-content-center w-100 mt-3">
                <button class="quiz-button--incorrect w-100"
                        @click="handleLogoutClick">
                    <Translate translationKey="quests-dashboard.logOut"
                               fallback="Log out" />
                </button>
            </div>
        </div>
    </TippyMobile>
</template>

<script setup lang="ts">
    import { computed, onMounted } from 'vue';
    import TippyMobile from '../../../global/widgets/tooltips/TippyMobile.vue';
    import { useTooltipStore } from '@/js/store/quests/game/modules/TippyMobileStore';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { useNavigationStore } from '@/js/store/quests/dashboard/NavigationStore';
    import { asset_cdn, route } from '@/js/helpers/helpers';
    import { getTrans } from '@/js/helpers/getTrans';
    import { getAvatar } from '@/js/helpers/avatars';
    import { LoaderVariantsEnum } from '@/js/enums/loaderVariants';
    import Loader from '../../../../components/Loader.vue';
    import { dashboardRoutesNames } from '../../constants/dashboardRoutesNames';

    const authStore = useAuthStore();
    const userStore = useUserStore();
    const navigationStore = useNavigationStore();
    const modalStore = useModalStore();
    const tooltipStore = useTooltipStore();

    const avatarImg = computed(() => (userStore.avatarHash ? getAvatar(userStore.avatarHash) : ''));

    async function fetchUserDataAndAvatar() {
        if (authStore.isUserLoggedIn) {
            try {
                await userStore.fetchQuestsUserData();
                modalStore.setAuthModal(false);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(`Error fetching data: ${error}`);
            }
        }
    }

    const handleLogoutClick = () => {
        authStore.logout();
        tooltipStore.setProfileTooltip(false);
    };

    onMounted(() => {
        fetchUserDataAndAvatar();
    });
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/design-system/variables";

.stats-title {
    font-size: 13px;
    text-align: center;
}

.stats-number {
    font-size: 21px;
    line-height: 24px;

    .degree-symbol {
        font-weight: 200;
    }
}

.profile-image {
    position: relative;
    min-width: 40px;
    min-height: 40px;
    padding: 4px;
    border: 1px solid $neon-blue;
    border-radius: 50%;
    background: $light-beige;
}

.loader-container {
    display: flex;
    height: 24px;
    align-items: center;
    justify-content: center;
}

.profile-tooltip-open {
    .stat-number,
    .stat-number span,
    .profile-image {
        transition: 0.2s ease-in-out;
    }

    i {
        position: absolute;
        right: 16px;
        bottom: -9px;
        color: $dropdown-purple;
        font-size: 12px;
    }
}

.stats-bits-mobile {
    .stat-number--fixed-height {
        height: 24px;
    }

    img,
    .stat-number,
    .stat-number span {
        transition: 0.2s ease-in-out;
    }

    &:hover {
        img,
        .profile-image {
            filter: brightness(118%);
            transform: scale(1.05);
        }

        .stat-number {
            transform: scale(1.05);

            span {
                color: $whisper;
            }
        }
    }

    .bits-wrapper {
        color: $baseWhite;
    }
}

.profile-tooltip {
    min-width: 110px;
    border-radius: 14px;

    a {
        text-decoration: none;
    }

    .profile-tooltip-item {
        padding: 12px 8px;
        color: $baseWhite;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;

        i {
            font-size: 16px;
        }
    }

    .profile-top-item-padding {
        padding: 10px 8px;
    }

    .profile-email-address {
        color: $gray400;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
    }
}
</style>
