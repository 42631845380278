import { defineStore } from 'pinia';
import { NotificationCountData } from '@/js/quests/dashboard/types/types';
import {
    getMoreToolbarNotifications,
    getToolbarNotificationCount,
    getToolbarNotifications,
} from '@/js/quests/dashboard/services/api';
import { useAuthStore } from '../../auth/AuthStore';
import { BINANCE_JOIN_LINK } from '@/js/quests/dashboard/constants';

enum NotificationDisplayType {
    TOOLBAR = 'toolbar',
    MODAL = 'modal',
    ALL = 'all',
}

interface NotificationData {
    display_type?: NotificationDisplayType,
    image_path?: string,
    slug?: string,
    subtitle?: string,
    title?: string,
    url?: string,
    guild_slug?: string,
    mission_slug?: string,
}

interface Notification {
    data?: NotificationData;
    id?: string;
    read_at?: string;
    type?: string;
}

interface NotificationsData {
    data: Notification[],
    meta?: {
        current_page?: number;
        from?: number;
        last_page?: number;
        links: []
        path?: string;
        per_page?: number;
        to?: number;
        total?: number;
    }
}

interface NotificationStoreState {
    isLoading: boolean,
    // separate loading state to not show loader when user repeatedly opens notification modal
    isLoadingMore: boolean,
    showLoadMore: boolean,
    currentPage?: number,
    notificationData: NotificationsData,
    notificationCountData?: NotificationCountData
}

export const loggedOutNotifications = [
    {
        title: 'Grab Binance $600 Bonus',
        subtitle: 'Claim your $600 Welcome Voucher for completing simple tasks on Binance.',
        image_path: 'assets/missions/notifications/reward.svg',
        url: BINANCE_JOIN_LINK,
        read_at: Date.now().toString(),
    },
    {
        title: 'You Just Got 50 Bits!',
        subtitle: 'Awesome! Start the first Mission & collect more rewards.',
        image_path: 'assets/missions/notifications/bits-earned.svg',
        read_at: Date.now().toString(),
    },
    {
        title: 'Claim NFT Certificate',
        subtitle: 'Finish the Official Web3 Exam & mint your free NFT.',
        image_path: 'assets/missions/notifications/nft-certificate-issued.svg',
        read_at: Date.now().toString(),
    },
];

export const useNotificationStore = defineStore({
    id: 'notification',
    state: (): NotificationStoreState => ({
        isLoading: false,
        isLoadingMore: false,
        showLoadMore: false,
        currentPage: null,
        notificationData: null,
        notificationCountData: null,
    }),

    actions: {
        setLoading(payload: boolean) {
            this.isLoading = payload;
        },
        setLoadingMore(payload: boolean) {
            this.isLoadingMore = payload;
        },
        async fetchNotificationCount() {
            const authStore = useAuthStore();

            if (authStore.isUserLoggedIn) {
                this.notificationCountData = (await getToolbarNotificationCount())?.data;
            }
        },
        async fetchNotifications() {
            this.setLoading(true);
            const authStore = useAuthStore();

            try {
                if (authStore.isUserLoggedIn) {
                    this.notificationData = await getToolbarNotifications();
                    this.showLoadMore = this.notificationData?.links?.next !== null;
                    this.currentPage = this.notificationData.meta.current_page;
                }
                this.setLoading(false);
            } finally {
                this.setLoading(false);
            }
        },
        async fetchMoreNotifications() {
            this.setLoadingMore(true);
            try {
                const oldArray = Array.from(this.notificationData.data);
                this.notificationData = await getMoreToolbarNotifications(this.currentPage + 1);
                const newData = Object.values(this.notificationData.data);
                this.notificationData.data = oldArray.concat(newData);

                this.showLoadMore = this.notificationData.links.next !== null;
                this.currentPage = this.notificationData.meta.current_page;
                this.setLoadingMore(false);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log('fetchMoreNotifications', e);
                this.setLoadingMore(false);
            }
        },
    },
});
