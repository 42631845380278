<template>
    <div class="guild-button"
         :class="{
             'guild-button--no-click': isCursorDisallowed,
             'guild-button--follow': isFollowButton,
             'guild-button--unfollow': isUnfollowButton,
         }"
         @mouseover="handleMouseOver"
         @mouseleave="handleMouseLeave"
         @click.stop="handleButtonClick">
        <i class="guild-button__icon"
           :class="{
               'guild-button__icon--unfollow fa-regular fa-heart': isUnfollowButton,
               'fa-solid fa-heart': !isUnfollowButton,
           }" />
        <div>{{ buttonText }}</div>
    </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { getTrans } from '@/js/helpers/getTrans';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';

    const props = defineProps<{
        guildSlug: string,
        isFollowed: boolean,
        isFollowMandatory: boolean,
    }>();

    const emit = defineEmits<{(e: 'click', slug: string, status: boolean): void; }>();

    const { isMobileOrTablet } = useDeviceSize();

    const guildSlug = computed(() => props.guildSlug);
    const isFollowMandatory = computed(() => props.isFollowMandatory);
    const isFollowed = computed(() => props.isFollowed);

    const isButtonHovered = ref(false);

    const isCursorDisallowed = computed(() => isFollowMandatory.value);
    const isFollowButton = computed(() => !isFollowed.value);
    const isUnfollowButton = computed(() => !isMobileOrTablet.value && !isFollowMandatory.value && isFollowed.value && isButtonHovered.value);

    const buttonTexts = {
        followingText: getTrans('quests-dashboard.following', 'Following'),
        followText: getTrans('quests-dashboard.follow', 'Follow'),
        unfollowText: getTrans('quests-dashboard.unfollow', 'Unfollow'),
    };

    const buttonText = computed(() => {
        if (isFollowMandatory.value) {
            return buttonTexts.followingText;
        }

        if (isFollowed.value) {
            return !isMobileOrTablet.value && isButtonHovered.value ? buttonTexts.unfollowText : buttonTexts.followingText;
        }

        return buttonTexts.followText;
    });

    const handleButtonClick = async (e: Event) => {
        e.preventDefault();

        if (isFollowMandatory.value) {
            return;
        }

        emit('click', guildSlug.value, !isFollowed.value);
    };

    const handleMouseOver = () => {
        isButtonHovered.value = true;
    };

    const handleMouseLeave = () => {
        isButtonHovered.value = false;
    };
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/variables";
@import "@/sass/partials/breakpoints";

.guild-button {
    all: unset;
    display: flex;
    width: 100px;
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 5px 8px;
    border: 1px solid $blue600;
    border-radius: 10px;
    color: $gray50;
    cursor: pointer;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    transition: all 200ms ease;

    @media (min-width: $sm-breakpoint) {
        padding: 5px 13px;
        font-size: 12px;

        &:hover {
            background: $blue900;
        }
    }

    &__icon {
        margin-right: 8px;
        color: $warning500;
        font-size: 14px;

        &--unfollow {
            color: $error600;
        }

        @media (min-width: $sm-breakpoint) {
            font-size: 16px;
        }
    }

    &--no-click {
        cursor: not-allowed;

        @media (min-width: $sm-breakpoint) {
            &:hover {
                background: $blue950;
            }
        }
    }

    &--follow {
        background-color: $blue600;

        @media (min-width: $sm-breakpoint) {
            &:hover {
                background-color: $blue700;
            }
        }
    }

    &--unfollow {
        @media (min-width: $sm-breakpoint) {
            &:hover {
                border-color: $error600;
                background-color: transparent;
            }
        }
    }
}
</style>
