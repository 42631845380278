<template>
    <transition name="dialog"
                appear>
        <div v-if="!modalStore.showExitAnimation"
             class="modal d-flex justify-content-center align-items-center modal-transition"
             @click="modalStore.setBybitDealModal(false)">
            <div class="modal-wrapper">
                <div class="modal-content text-center p-5 justify-content-between justify-content-md-start"
                     @click.stop>
                    <div class="text-left">
                        <span class="close-button"
                              @click="modalStore.setBybitDealModal(false)">&times;</span>
                    </div>
                    <div>
                        <div class="bits text-uppercase p-0 m-0 mb-3">
                            <img :src="asset_cdn('assets/bybit-gift.svg')"
                                 :alt="getTrans('quests-dashboard.reward', 'Reward')"
                                 :title="getTrans('quests-dashboard.reward', 'Reward')"
                                 class="mb-2"
                                 width="170">
                        </div>
                        <div class="modal-title">
                            {{ bybitDealNotification?.data?.title || 'Claim your reward' }}
                        </div>
                        <div class="modal-subtitle">
                            {{ bybitDealNotification?.data?.subtitle || 'Claim 1,000 Bits and celebrate Bybit\'s 6th birthday with a shot at $2.2M prize pool!' }}
                        </div>
                    </div>
                    <div class="modal-content-animated is-animated d-flex justify-content-center align-items-center">
                        <button v-focus
                                class="quiz-button--correct mb-2 text-uppercase"
                                @click="openNotificationUrl()"
                                @keyup.enter="modalStore.setBybitDealModal(false)">
                            <Translate translationKey="quests-dashboard.claimNow"
                                       fallback="CLAIM NOW"/>
                            🔥
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
    import {
        onBeforeUnmount, onMounted, ref, watchEffect,
    } from 'vue';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { NotificationModalTypeEnum } from '../../types/enums';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { Notification } from '../../types/types';
    import { EventTypeEnum, KeyEnum } from '@/js/enums/enums';
    import { useModalActive } from '@/js/composables/useModalActive';
    import { asset_cdn } from '@/js/helpers/helpers';
    import { getTrans } from '@/js/helpers/getTrans';

    const userStore = useUserStore();
    const modalStore = useModalStore();

    let interval: NodeJS.Timeout | undefined;

    const notifications = ref<Notification[]>([]);
    const bybitDealNotification = ref();

    const onEscapePressed = (e: { key: string; which: number; }) => {
        if (e.key === KeyEnum.ESCAPE || e.key === KeyEnum.ESC || e.which === 27) {
            modalStore.setBybitDealModal(false);
        }
    };

    const openNotificationUrl = () => {
        const DEFAULT_BYBIT_DEAL_URL = '/extra/bybit-deal';

        window.location.href = bybitDealNotification.value?.data?.url ?? DEFAULT_BYBIT_DEAL_URL;
    };

    watchEffect(() => {
        if (modalStore.modals.showBybitDeal) {
            notifications.value = userStore?.notifications ?? [];
            const filteredNotifications = notifications.value.filter(
                (notification) => notification.type === NotificationModalTypeEnum.BybitDeal,
            );
            bybitDealNotification.value = filteredNotifications.length > 0 ? filteredNotifications[0] : null;
        }
    });

    useModalActive();

    onMounted(() => {
        document.addEventListener(EventTypeEnum.KEYDOWN, onEscapePressed);
    });

    onBeforeUnmount(() => {
        document.removeEventListener(EventTypeEnum.KEYDOWN, onEscapePressed);
        if (interval) {
            clearInterval(interval);
        }
    });
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";

.modal-transition {
    .modal-wrapper {
        max-width: 500px;
        max-height: 500px;
    }

    .modal-content {
        padding: 16px 11px;

        .modal-content-animated {
            overflow: hidden;
            max-height: 0;
            transition: max-height 1.4s ease-in-out;
        }

        .modal-content-animated.is-animated {
            max-height: 500px;
        }

        @media (max-width: 650px) {
            padding: 8px 16px 16px 16px !important;
        }
    }

    div {
        color: $baseWhite;
    }

    .modal-title {
        padding-bottom: 16px;
        font-size: 26px;
        font-weight: 700;
        line-height: 32px;
    }

    .modal-subtitle {
        padding-bottom: 32px;
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
    }

    .bits {
        margin-bottom: 16px;
        color: #f1c92c;
        font-size: 65px;
        font-weight: 700;
        text-align: center;

        .bits-value {
            font-weight: 700;
            transition: 1s filter linear;

            span {
                font-size: 25px;
            }
        }
    }

    @media (max-width: 768px) {
        .modal-title {
            font-size: 40px;
            line-height: 46px;
        }

        .modal-subtitle {
            font-size: 16px;
        }

        .bits {
            font-size: 64px;

            img {
                width: 60px;
            }
        }
    }
}
</style>
