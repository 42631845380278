<template>
    <TangibleRewardModal v-if="modalStore.modals.showTangibleRewardClaim"
                         :isOpen="modalStore.modals.showTangibleRewardClaim" />
</template>

<script setup lang="ts">
    import {
        computed, watch,
    } from 'vue';
    import { useRoute } from 'vue-router';
    import TangibleRewardModal from '@/js/quests/dashboard/TangibleRewardModal.vue';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { useAuthStore } from '@/js/store/auth/AuthStore';

    const authStore = useAuthStore();
    const modalStore = useModalStore();
    const userStore = useUserStore();
    const route = useRoute();

    const WINNERS_FORM = 'winners-form';
    const isLoggedIn = computed(() => authStore.isUserFetchCompleted && authStore.isUserLoggedIn);

    watch(() => [isLoggedIn.value, route.query.action, route.query.data], async () => {
        if (!isLoggedIn.value || route.query.action !== WINNERS_FORM || !route.query.data) {
            return;
        }

        await userStore.getTangibleRewardData(route.query.data as string);

        if (userStore.tangible.id === route.query.data) {
            modalStore.setTangibleRewardClaimModal(true);
        }
    }, { immediate: true });
</script>
