<template>
    <div v-if="notification?.url || notification?.mission_slug"
         @click="handleNotificationClick">
        <div class="notification-row"
             :class="{'unread': !notification?.read_at}">
            <div class="image-wrapper">
                <img width="55"
                     height="55"
                     :alt="notification?.title"
                     :src="asset_cdn(notification?.image_path, 55) || ''"
                     :title="notification?.title">
            </div>
            <div class="notification-item-content">
                <h6>
                    {{ notification?.title }}
                </h6>
                <div class="subtitle">
                    {{ notification?.subtitle }}
                </div>
            </div>
            <div v-if="!notification?.read_at"
                 class="red-dot" />
        </div>
    </div>
    <div v-else
         @click="notification.onClick">
        <div class="notification-row"
             :class="{'unread': !notification?.read_at}">
            <div class="image-wrapper">
                <img width="55"
                     height="55"
                     :src="asset_cdn(notification?.image_path, 55) || ''"
                     :alt="notification?.title"
                     :title="notification?.title">
            </div>
            <div class="notification-item-content">
                <h6>
                    {{ notification?.title }}
                </h6>
                <div class="subtitle">
                    {{ notification?.subtitle }}
                </div>
            </div>
            <div v-if="!notification?.read_at"
                 class="red-dot" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { asset_cdn } from '@/js/helpers/helpers';
    import { notificationTypes } from '@/js/quests/dashboard/constants/notificationTypes';
    import { useNavigationStore } from '@/js/store/quests/dashboard/NavigationStore';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';
    import { questsRoutes } from '@/js/constants/questsRoutes';
    import { dashboardRoutesPaths } from '@/js/quests/dashboard/constants/dashboardRoutesPaths';

    interface NotificationRow {
        title: string,
        subtitle: string,
        image_path: string,
        url?: string,
        read_at?: string,
        type?: string,
        guild_slug?: string,
        mission_slug?: string,
        onClick?:() => void,
    }

    const { notification } = defineProps<{notification: NotificationRow}>();

    const navigationStore = useNavigationStore();

    const { isMobileOrTablet } = useDeviceSize();

    const showStreakCard = () => {
        if (isMobileOrTablet.value) {
            navigationStore.showMobileStreak = true;
        } else {
            navigationStore.showStreakTippyFunction();
        }
    };

    const constructMissionUrl = (missionSlug?: string, guildSlug?: string): string => {
        if (!missionSlug || !guildSlug) {
            return questsRoutes.MISSIONS_ROUTE;
        }

        return `${dashboardRoutesPaths.MISSION_ROUTE_PATH.replace(':guildSlug', guildSlug).replace(':slug', missionSlug)}`;
    };

    const handleNotificationClick = () => {
        if (notification?.type === notificationTypes.QUEST_STREAK_INCREMENTED_NOTIFICATION) {
            showStreakCard();
        } else if (notification?.type === notificationTypes.QUEST_MISSION_COMPLETED_NOTIFICATION) {
            const guildSlug = notification?.guild_slug;
            const missionSlug = notification?.mission_slug;

            window.location.href = constructMissionUrl(missionSlug, guildSlug);
        } else if (notification?.url) {
            window.location.href = notification.url;
        }
    };
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/variables";

.notification-row {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    transition: all 0.3s;

    &.unread {
        background-color: $purple-active;
    }

    .image-wrapper img {
        border-radius: 12px;
    }

    .notification-item-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 14px;
        text-align: start;

        h6 {
            color: $baseWhite;
            font-size: 13px;
        }
    }

    .subtitle {
        overflow: hidden;
        color: $gray200;
        font-size: 10px;
        font-weight: 500;
        line-height: 11px;
        text-align: start;
        text-overflow: ellipsis;
    }

    .red-dot {
        position: absolute;
        top: calc(50% - 3px);
        right: 8px;
        width: 6px;
        min-width: 6px;
        height: 6px;
        min-height: 6px;
        border-radius: 50%;
        background-color: $new-notification-color;
    }

    &:hover {
        background-color: $pastel-purple;
    }
}
</style>
