<template>
    <div :id="HtmlIds.MOBILE_FOOTER_ID"
         class="mobile-footer">
        <div class="mobile-footer__side">
            <MobileFooterTab class="mobile-footer__tab"
                             :title="getTrans('quests-dashboard.missions', 'Missions')"
                             :routerLink="dashboardRoutesNames.MISSIONS_ROUTE_NAME"
                             iconClass="fa-solid fa-star" />
            <MobileFooterTab class="mobile-footer__tab"
                             :title="getTrans('quests-dashboard.guilds.menuTitle', 'Guilds')"
                             :routerLink="dashboardRoutesNames.GUILDS_ROUTE_NAME"
                             iconClass="fa-solid fa-helmet-battle" />
            <MobileFooterTab class="mobile-footer__tab"
                             :title="getTrans('quests-dashboard.friends', 'Friends')"
                             :routerLink="dashboardRoutesNames.FRIENDS_ROUTE_NAME"
                             iconClass="fa-solid fa-user-group"/>
        </div>
        <div class="mobile-footer__side">
            <MobileFooterTab class="mobile-footer__tab"
                             :isLinkElement="authStore.isUserLoggedIn"
                             :title="getTrans('quests-dashboard.wallet', 'Wallet')"
                             :routerLink="dashboardRoutesNames.WALLET_ROUTE_NAME"
                             iconClass="fa-brands fa-bitcoin"
                             @click="handleAuthRequiredTabClick" />
            <MobileFooterTab class="mobile-footer__tab"
                             :title="getTrans('quests-dashboard.airdrops', 'Airdrops')"
                             :routerLink="dashboardRoutesNames.LEADERBOARD_ROUTE_NAME"
                             iconClass="fa-solid fa-gift"
                             isNew/>
            <MobileFooterTab class="mobile-footer__tab"
                             :title="getTrans('quests-dashboard.bonus.menuTitle', 'Bonus')"
                             :routerLink="dashboardRoutesNames.BONUS_ROUTE_NAME"
                             iconClass="fa-solid fa-candy"
                             isHot/>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { HtmlIds } from '../../../constants/htmlIds';
    import { dashboardRoutesNames } from '../../../constants/dashboardRoutesNames';
    import MobileFooterTab from './components/MobileFooterTab.vue';
    import { getTrans } from '@/js/helpers/getTrans';

    const authStore = useAuthStore();
    const modalStore = useModalStore();

    const handleAuthRequiredTabClick = () => {
        if (!authStore.isUserLoggedIn) {
            modalStore.setAuthModal(true);
        }
    };
</script>

<style scoped lang="scss">
@import "@/sass/missions-partials/z-index";
@import "@/sass/partials/variables";
@import "@/sass/partials/breakpoints";

$mobile-footer-height: 60px;
$play-button-width: 80px;
$sm-play-button-width: 64px;

$xs-breakpoint: 375px;

.mobile-footer {
    position: fixed;
    z-index: $mobile-footer-z-index;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    height: $mobile-footer-height;
    justify-content: space-between;
    padding: 12px 2px;
    background-color: $blackberry-purple;

    @media (min-width: $sm-breakpoint) {
        padding: 12px;
    }

    &__side {
        display: flex;
        width: 100%;
        max-width: 50%;
        transition: max-width 600ms ease-in-out;
    }

    &__tab {
        display: flex;
        flex: 1;
        text-decoration: none;
    }

    &--animated { // Added with JS
        justify-content: space-between;

        .mobile-footer__side {
            max-width: calc(50% - $sm-play-button-width / 2);

            @media (min-width: $xs-breakpoint) {
                max-width: calc(50% - $play-button-width / 2);
            }
        }
    }
}
</style>
