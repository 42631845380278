<template>
    <div class="extension-connected-modal">
        <BaseModal v-if="isOpen"
                   :padding="isMobileOrTablet? '16px' : '32px'"
                   maxWidth="520px"
                   useClickOutsideClose
                   @close="handleClose">
            <template #header>
                <img :src="asset_cdn('assets/missions/notifications/notifications-arrow.png')"
                     class="extension-connected-modal__header-arrow"
                     :width="arrowWidth"
                     :height="arrowHeight"
                     alt="arrow to notification"
                     title="arrow to notification">
            </template>
            <template #content>
                <div class="extension-connected-modal__content">
                    <img :src="asset_cdn('assets/missions/completed.png')"
                         class="1extension-connected-modal__header-image"
                         :width="64"
                         :height="64"
                         alt="notification"
                         title="notification">
                    <h2 class="extension-connected-modal__title">
                        <Translate translationKey="quests-dashboard.extensionConnected"
                                   fallback="Extension Connected — Just One Step to Start Earning!" />
                    </h2>
                    <div class="extension-connected-modal__subtitle">
                        <Translate translationKey="quests-dashboard.openTheBitDegreeExtension"
                                   fallback="Open the BitDegree extension now to activate mining and start earning instantly." />
                    </div>
                </div>
            </template>
            <template #footer/>
        </BaseModal>
    </div>
</template>

<script setup lang="ts">
    import BaseModal from '@/js/components/BaseModal.vue';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { useModalActive } from '@/js/composables/useModalActive';
    import { asset_cdn } from '@/js/helpers/helpers';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';

    interface Props {
        isOpen: boolean;
    }
    defineProps<Props>();

    const modalStore = useModalStore();

    const { isMobileOrTablet } = useDeviceSize();

    const arrowWidth = '85';
    const arrowHeight = '191';

    const handleClose = () => {
        modalStore.setExtensionConnectedModal(false);
    };

    useModalActive();
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";

.extension-connected-modal {
    &__header-arrow {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-50%) translateX(100%) scale(-1, 1);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $baseWhite;
    }

    &__title {
        margin-top: 16px;
        margin-bottom: 32px;
        font-size: 32px;
        font-weight: 700;
        line-height: 1.2;
        text-align: center;
    }

    &__subtitle {
        font-size: 18px;
        line-height: 1.2;
        text-align: center;
    }
}
</style>
