import { deleteUrlParam } from '@/js/helpers/urlUtils';
import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';

const ACTION_QUERY_PARAM_KEY = 'action';
const ACTION_QUERY_PARAM_VALUE = 'extension-connect';

export const useExtensionConnected = () => {
    const modalStore = useModalStore();

    const checkExtensionConnected = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const actionProp = queryParams.get(ACTION_QUERY_PARAM_KEY);

        if (actionProp && actionProp === ACTION_QUERY_PARAM_VALUE) {
            modalStore.setExtensionConnectedModal(true);
            deleteUrlParam(ACTION_QUERY_PARAM_KEY, queryParams);
        }
    };

   return {
       checkExtensionConnected,
   };
};
