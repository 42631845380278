<template>
    <div class="dashboard-bar">
        <RouterLink :to="{ name: dashboardRoutesNames.GUILDS_STUDIO_ROUTE_NAME }"
                    class="dashboard-bar__brand">
            Guilds <span class="dashboard-bar__text--dark"> Studio</span>
        </RouterLink>
        <div class="dashboard-bar__items">
            <template v-if="isGuildStudioPage">
                <span class="dashboard-bar__text--dark dashboard-bar__separator">
                    |
                </span>
                <DashboardBarItem :link="guildCreateLink"
                                  iconClass="fa-solid fa-plus"
                                  text="Create New Guild" />
            </template>
            <template v-if="isGuildPage">
                <span class="dashboard-bar__text--dark dashboard-bar__separator">
                    |
                </span>
                <DashboardBarItem :link="guildEditLink"
                                  iconClass="fa-solid fa-pencil"
                                  text="Edit Guild" />
                <span class="dashboard-bar__text--dark dashboard-bar__separator">
                    |
                </span>
                <DashboardBarItem :link="missionCreateLink"
                                  iconClass="fa-solid fa-plus"
                                  text="Create New Mission" />
            </template>
            <template v-if="isMissionPage">
                <span class="dashboard-bar__text--dark dashboard-bar__separator">
                    |
                </span>
                <DashboardBarItem :link="missionEditLink"
                                  iconClass="fa-solid fa-pencil"
                                  text="Edit Mission" />
                <span class="dashboard-bar__text--dark dashboard-bar__separator">
                    |
                </span>
                <template v-if="isLuckyDrawCompleted">
                    <DashboardBarItem text="Relaunch Lucky Draw"
                                      iconClass="fa-solid fa-rotate-right"
                                      @click.stop="handleLuckyDrawRestart" />
                    <span class="dashboard-bar__text--dark dashboard-bar__separator">
                        |
                    </span>
                </template>
                <DashboardBarItem :link="roundsLink"
                                  iconClass="fa-solid fa-list"
                                  :text="dashboardStore.hasMissionRounds ? 'Edit Rounds' : 'Add Rounds'" />
                <span class="dashboard-bar__text--dark dashboard-bar__separator">
                    |
                </span>
                <DashboardBarItem text="Show Winners"
                                  iconClass="fa-regular fa-gift"
                                  @click.stop="handleOwnerLuckyDrawWinnersClick"/>

                <template v-if="wasMissionPublished || missionIsDraft">
                    <span class="dashboard-bar__text--dark dashboard-bar__separator">
                        |
                    </span>

                    <template v-if="isPreview">
                        <DashboardBarItem text="Switch to Live"
                                          @click.stop="openLiveMission">
                            <span class="text-icon">Preview</span>
                        </DashboardBarItem>
                    </template>
                    <template v-else>
                        <DashboardBarItem text="Switch to Preview"
                                          @click.stop="openDraftMission">
                            <span class="text-icon text-icon--live">Live</span>
                        </DashboardBarItem>
                    </template>
                </template>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useRouter } from 'vue-router';
    import DashboardBarItem from '@/js/quests/dashboard/components/dashboard-bar/DashboardBarItem.vue';
    import { FULL_BUILDER_ROUTE_PATHS } from '@/js/pages/builder/routes/builderRoutePaths';
    import { dashboardRoutesNames } from '@/js/quests/dashboard/constants/dashboardRoutesNames';
    import { dashboardRoutesPaths } from '@/js/quests/dashboard/constants/dashboardRoutesPaths';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { useLuckyDrawWinnerStore } from '@/js/store/quests/dashboard/LuckyDrawWinnerStore';
    import { useDashboardStore } from '@/js/store/quests/dashboard/DashboardStore';
    import { ContentStatusEnum } from '@/js/manage/types/enums';

    const router = useRouter();
    const modalStore = useModalStore();
    const luckyDrawWinnerStore = useLuckyDrawWinnerStore();
    const dashboardStore = useDashboardStore();

    const { setShowOwnerData } = luckyDrawWinnerStore;

    const guildSlug = computed(() => router.currentRoute.value.params.guildSlug as string);
    const missionSlug = computed(() => router.currentRoute.value.params.slug as string);

    const isGuildStudioPage = computed(() => router.currentRoute.value.name === dashboardRoutesNames.GUILDS_STUDIO_ROUTE_NAME);
    const isGuildPage = computed(() => router.currentRoute.value.name === dashboardRoutesNames.GUILD_ROUTE_NAME);

    const missionRoutes = [dashboardRoutesNames.MISSION_ROUTE_NAME, dashboardRoutesNames.MISSION_PREVIEW_ROUTE_NAME];
    const isMissionPage = computed(() => missionRoutes.includes(router.currentRoute.value.name));
    const isPreview = computed(() => router.currentRoute.value.name === dashboardRoutesNames.MISSION_PREVIEW_ROUTE_NAME);

    const guildCreateLink = computed(() => FULL_BUILDER_ROUTE_PATHS.GUILD_BUILDER);
    const guildEditLink = computed(() => FULL_BUILDER_ROUTE_PATHS.GUILD_BUILDER_EDIT.replace(':guildSlug', guildSlug.value));
    const missionCreateLink = computed(() => FULL_BUILDER_ROUTE_PATHS.MISSION_INTRO.replace(':guildSlug', guildSlug.value));
    const missionEditLink = computed(() => FULL_BUILDER_ROUTE_PATHS.MISSION_BUILDER_EDIT
        .replace(':guildSlug', guildSlug.value)
        .replace(':missionSlug', missionSlug.value));
    const roundsLink = computed(() => FULL_BUILDER_ROUTE_PATHS.ROUND_BUILDER
        .replace(':guildSlug', guildSlug.value)
        .replace(':missionSlug', missionSlug.value));

    const missionShowLink = computed(() => dashboardRoutesPaths.MISSION_ROUTE_PATH
        .replace(':guildSlug', guildSlug.value)
        .replace(':slug', missionSlug.value));

    const missionPreviewLink = computed(() => dashboardRoutesPaths.MISSION_PREVIEW_ROUTE_PATH
        .replace(':guildSlug', guildSlug.value)
        .replace(':slug', missionSlug.value));

    const isLuckyDrawCompleted = computed(() => {
        if (!dashboardStore.missionData) {
            return false;
        }

        return !!dashboardStore.missionData.data.lucky_draw?.completed_at;
    });

    const wasMissionPublished = computed(() => !!dashboardStore.missionData?.data?.published_at);
    const missionIsDraft = computed(() => dashboardStore.missionData?.data?.content_status === ContentStatusEnum.DRAFT);

    const handleLuckyDrawRestart = () => {
        modalStore.setLuckyDrawRestartModal(true);
    };

    const handleOwnerLuckyDrawWinnersClick = () => {
        setShowOwnerData();
        modalStore.setLuckyDrawWinnersModal(true);
    };

    const openDraftMission = () => {
        router.push(missionPreviewLink.value);
    };
    const openLiveMission = () => {
        router.push(missionShowLink.value);
    };
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/missions-partials/z-index";
@import "@/sass/design-system/variables";

.dashboard-bar {
    position: fixed;
    z-index: $dashboard-navigation-wrapper-z-index;
    top: 0;
    display: flex;
    width: 100%;
    height: $dashboard-bar-height;
    align-items: center;
    padding: 0 8px;
    border-bottom: 1px solid $dark-theme-border;
    background-color: $blackcurrant-dark;
    color: $baseWhite;

    &__text {
        &--dark {
            font-weight: 300;
            opacity: 0.5;
        }
    }

    &__separator {
        user-select: none;
    }

    &__brand {
        margin-right: 14px;
        color: $baseWhite;
        font-size: 15px;
        font-weight: 700;
        text-decoration: none;
        transition: opacity 200ms ease-in-out;

        &:hover {
            opacity: 0.7;
        }
    }

    &__items {
        display: flex;
        align-items: center;
        font-size: 14px;
        gap: 16px;
    }
}

.text-icon {
    padding: 2px 6px;
    border-radius: 18px;
    background-color: $gray50;
    color: $baseBlack;
    font-size: 11px;
    font-weight: 700;

    &--live {
        background-color: $blurred-green;
    }
}
</style>
