<template>
    <div :class="['shadow-scroll-indicator', { 'show': props.show }]" />
</template>

<script setup lang="ts">
    const props = defineProps<{show: boolean}>();
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";

.shadow-scroll-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-image: linear-gradient(to top, $purple-dark 0%, rgba(86, 33, 122, 0) 100%);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;

    &.show {
        opacity: 1;
    }
}
</style>
