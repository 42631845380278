<template>
    <div class="navigation-link"
         @click="navigationStore.showMenuCard = false">
        <template v-if="isLinkElement">
            <a v-if="externalLink"
               :href="externalLink"
               target="_blank"
               class="link">
                <span v-if="icon">
                    {{ icon }}
                </span>
                <span>
                    <Translate :translationKey="translationKey"
                               :fallback="fallback" />
                </span>
            </a>
            <RouterLink v-else
                        :to="{ name: routeLink }"
                        class="link">
                <span v-if="icon">
                    {{ icon }}
                </span>
                <span>
                    <Translate :translationKey="translationKey"
                               :fallback="fallback" />
                </span>
                <div v-if="isNew || isHot"
                     class="link__badge"
                     :class="{'link__badge--new': isNew, 'link__badge--hot': isHot}">
                    <Translate v-if="isNew"
                               translationKey="quests-dashboard.new"
                               fallback="New" />
                    <Translate v-else-if="isHot"
                               translationKey="quests-dashboard.hot"
                               fallback="Hot" />
                </div>
            </RouterLink>
        </template>
        <div v-else
             class="link">
            <span v-if="icon">
                {{ icon }}
            </span>
            <span>
                <Translate :translationKey="translationKey"
                           :fallback="fallback" />
            </span>
            <div v-if="isNew || isHot"
                 class="link__badge"
                 :class="{'link__badge--new': isNew, 'link__badge--hot': isHot}">
                <Translate v-if="isNew"
                           translationKey="quests-dashboard.new"
                           fallback="New" />
                <Translate v-else-if="isHot"
                           translationKey="quests-dashboard.hot"
                           fallback="Hot" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { RouteRecordName } from 'vue-router';
    import { useNavigationStore } from '@/js/store/quests/dashboard/NavigationStore';
    import Translate from '@/js/helpers/Translate.vue';

    const navigationStore = useNavigationStore();

    interface IProps {
        translationKey: string,
        fallback: string,
        routeLink?: RouteRecordName,
        icon?: string,
        isLinkElement?: boolean,
        externalLink?: string,
        isNew?: boolean,
        isHot?: boolean,
    }

    withDefaults(defineProps<IProps>(), {
        routeLink: null,
        icon: null,
        isLinkElement: true,
        externalLink: null,
    });
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";

.navigation-link {
    margin-bottom: 10px;
    color: $baseWhite;
    text-decoration: none;

    &:last-child {
        margin-bottom: 0;
    }

    .link {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        &__badge {
            height: 15px;
            padding: 0 3px;
            border-radius: 6px;
            color: $baseWhite;
            font-size: 10px;
            text-transform: uppercase;

            &--new {
                background: $brand400;
            }

            &--hot {
                background: $error600;
            }
        }
    }
}
</style>
