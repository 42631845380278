<template>
    <div class="modal-wrapper">
        <transition name="dialog"
                    appear>
            <div v-if="!modalStore.showExitAnimation"
                 class="modal d-flex justify-content-center align-items-center modal-transition"
                 @click="modalStore.setForceEmailVerificationModal(false)">
                <div class="modal-wrapper">
                    <div class="modal-content justify-content-between justify-content-md-start"
                         @click.stop>
                        <div class="text-left">
                            <span class="close-button"
                                  @click="modalStore.setForceEmailVerificationModal(false)">&times;</span>
                        </div>
                        <div>
                            <div class="locked-image d-flex justify-content-center align-items-center">
                                <img :src="asset_cdn('assets/missions/locked.svg')"
                                     :alt="getTrans('quests-dashboard.missionLocked', 'Mission Locked')"
                                     :title="getTrans('quests-dashboard.missionLocked', 'Mission Locked')">
                            </div>
                            <div class="modal-title text-center mt-3">
                                <Translate translationKey="quests-dashboard.yourEmailIsNotVerified"
                                           fallback="Your Email is Not Verified" />
                            </div>
                            <div class="modal-subtitle text-center mb-2">
                                <Translate translationKey="quests-dashboard.toContinueCollectingAwesomePrizes"
                                           fallback="To continue collecting awesome prizes & gaining knowledge, you need to verify your email address." />
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center mb-3">
                            <button class="quiz-button--correct"
                                    @click="modalStore.setEmailVerifyModal(true)">
                                <Translate translationKey="quests-dashboard.verifyEmail"
                                           fallback="Verify Email" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, onBeforeUnmount } from 'vue';
    import { asset_cdn } from '@/js/helpers/helpers';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { getTrans } from '@/js/helpers/getTrans';
    import { EventTypeEnum, KeyEnum } from '@/js/enums/enums';

    const modalStore = useModalStore();

    const onEscapePressed = (event: KeyboardEvent) => {
        if (event.key === KeyEnum.ESCAPE || event.key === KeyEnum.ESC || event.which === 27) {
            modalStore.setForceEmailVerificationModal(false);
        }
    };

    onMounted(() => {
        document.addEventListener(EventTypeEnum.KEYDOWN, onEscapePressed);
    });

    onBeforeUnmount(() => {
        document.removeEventListener(EventTypeEnum.KEYDOWN, onEscapePressed);
    });
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/design-system/fonts";
@import "@/sass/design-system/variables";

.modal-transition {
    .modal-wrapper {
        max-width: 600px;
        max-height: 500px;
    }

    .modal-content {
        padding: 27px;

        @media (max-width: 650px) {
            padding: 8px 16px 16px 16px;
        }
    }

    div {
        color: $baseWhite;
    }

    .modal-title {
        font-family: $font-quicksand;
        font-size: 29px;
        font-weight: 700;
    }

    .modal-subtitle {
        padding: 16px 0;
        font-size: 16px;
        font-weight: 500;
    }

    .locked-image img {
        width: 70px;
    }
}
</style>
