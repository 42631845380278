<template>
    <div class="d-inline-flex align-items-center justify-content-center px-1"
         @click="navigationStore.showMobileStreak=true">
        <div class="d-flex align-items-center justify-content-center">
            <StreakFire :color="Colors.STREAK_FIRE_COLOR"
                        :title="authStore.isUserLoggedIn
                            ? getTrans('quests-dashboard.streakCount', 'Streak Count') + ': ' + userStore.streakCount
                            : getTrans('quests-dashboard.connectToStreak', 'Connect to Streak')"
                        width="30" />
        </div>
        <div v-if="authStore.isUserLoggedIn"
             class="ml-2">
            <div class="stats-title">
                <Translate translationKey="quests-dashboard.streak"
                           fallback="Streak" />
            </div>
            <div class="font-weight-bold text-center stats-number">
                <div v-if="userStore.isLoading"
                     class="loader-container">
                    <Loader :size="16"
                            :variant="LoaderVariantsEnum.WHITE" />
                </div>
                <span v-else
                      class="stats-number">
                    {{ userStore.streakCount || 0 }}
                </span>
            </div>
        </div>
    </div>
    <Transition>
        <MobileStreakContent v-if="navigationStore.showMobileStreak" />
    </Transition>
</template>

<script setup lang="ts">
    import { getTrans } from '@/js/helpers/getTrans';
    import StreakFire from '../../svg/StreakFire.vue';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import MobileStreakContent from './MobileStreakContent.vue';
    import { useNavigationStore } from '@/js/store/quests/dashboard/NavigationStore';
    import { Colors } from '../../constants/colors';
    import { LoaderVariantsEnum } from '@/js/enums/loaderVariants';
    import Loader from '../../../../components/Loader.vue';

    const authStore = useAuthStore();
    const userStore = useUserStore();
    const navigationStore = useNavigationStore();
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";

.stats-title {
    font-size: 13px;
}

.stats-number {
    font-size: 21px;
    line-height: 24px;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.loader-container {
    display: flex;
    height: 24px;
    align-items: center;
    justify-content: center;
}
</style>
