<template>
    <div class="browser-notifications-modal">
        <BaseModal v-if="isOpen"
                   padding="0px 0px 16px 0px"
                   :maxWidth="`${width}px`"
                   theme="notification-theme"
                   useClickOutsideClose
                   @close="handleClose">
            <template #header>
                <img :src="asset_cdn('assets/missions/notifications/notifications-arrow.png')"
                     class="browser-notifications-modal__header-arrow"
                     :width="arrowWidth"
                     :height="arrowHeight"
                     alt="arrow to notification"
                     title="arrow to notification">
                <img :src="asset_cdn('assets/missions/notifications/notifications.png')"
                     class="browser-notifications-modal__header-image"
                     :width="width"
                     :height="height"
                     alt="notification"
                     title="notification">
            </template>
            <template #content>
                <div class="browser-notifications-modal__content">
                    <div class="browser-notifications-modal__icon">
                        🎁
                    </div>
                    <h2 class="browser-notifications-modal__title">
                        <Translate translationKey="quests-dashboard.neverMissYourRewards"
                                   fallback="Never miss your rewards!"/>
                    </h2>
                    <div class="browser-notifications-modal__subtitle">
                        <Translate translationKey="quests-dashboard.turnNotificationsOn"
                                   fallback="Turn notifications on to get updates about your rewards, airdrops and prizes." />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="browser-notifications-modal__actions">
                    <BaseButton v-if="isSubscribeButton"
                                class="browser-notifications-modal__action--subscribe"
                                :variant="ButtonVariantEnum.PRIMARY"
                                @click="handleClickSubscribe">
                        <Translate translationKey="quests-dashboard.subscribe"
                                   fallback="Subscribe" />
                    </BaseButton>
                    <BaseButton :variant="ButtonVariantEnum.QUATERNARY"
                                class="browser-notifications-modal__action--close"
                                @click="handleClose">
                        <Translate translationKey="quests-dashboard.later"
                                   fallback="Later" />
                    </BaseButton>
                </div>
            </template>
        </BaseModal>
    </div>
</template>

<script setup lang="ts">
    import { computed, watch } from 'vue';
    import BaseModal from '@/js/components/BaseModal.vue';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { useModalActive } from '@/js/composables/useModalActive';
    import { asset_cdn } from '@/js/helpers/helpers';
    import { ButtonVariantEnum } from '@/js/types/components/ButtonVariantEnum';
    import BaseButton from '@/js/components/BaseButton.vue';
    import { useBrowserNotifications } from '@/js/quests/dashboard/composables/useBrowserNotifications';
    import { isFirefoxDesktop } from '@/js/auth/helpers/deviceUtils';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';

    interface Props {
        isOpen: boolean;
    }
    defineProps<Props>();

    const modalStore = useModalStore();
    const userStore = useUserStore();

    const isBrowserFirefoxDesktop = computed(() => isFirefoxDesktop());
    const isNewUserSignup = computed(() => userStore.isNewUserSignup);
    const isSubscribeButton = computed(() => !isNewUserSignup.value && isBrowserFirefoxDesktop.value);

    const {
        isSubscribeAvailable,
        isInitialiseCompleted,
        isSubscribeCompleted,
        isPermissionGranted,
        activateSubscription,
        subscribeToNotifications,
        setRemindLater,
    } = useBrowserNotifications();

    const width = '520';
    const height = '91';

    const arrowWidth = '85';
    const arrowHeight = '191';

    const handleClose = () => {
        setRemindLater();

        modalStore.setBrowserNotificationsModal(false);
    };

    const handleClickSubscribe = async () => {
        await subscribeToNotifications();
    };

    useModalActive();

    watch(() => isInitialiseCompleted.value, async (newValue) => {
        if (newValue) {
            if (!isSubscribeAvailable.value) {
                handleClose();
            } else if (!isSubscribeButton.value) {
                await subscribeToNotifications();
            }
        }
    }, { immediate: true });

    watch(() => isSubscribeCompleted.value, async (newValue) => {
        if (newValue) {
            if (isPermissionGranted.value) {
                activateSubscription();
            }

            handleClose();
        }
    });
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";

.browser-notifications-modal {
    &__header-arrow {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-50%) translateX(-100%);
    }

    &__header-image {
        width: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__icon {
        margin-top: 24px;
        font-size: 80px;
        line-height: 1;
    }

    &__title {
        margin-top: 16px;
        margin-bottom: 8px;
        font-size: 32px;
        font-weight: 700;
        text-align: center;
    }

    &__subtitle {
        font-size: 18px;
        text-align: center;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 32px 32px 16px;
        gap: 16px;
    }

    &__action--subscribe {
        margin-bottom: 4px;
    }

    &__action--close {
        color: $gray500;

        &:active:enabled,
        &:hover:enabled {
            color: $gray400;
        }
    }
}
</style>
