<template>
    <div class="notifications-bell-wrapper">
        <span v-if="unreadCount"
              class="number">{{ unreadCount }}</span>
        <i class="fa-solid fa-bell" />
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { loggedOutNotifications, useNotificationStore } from '@/js/store/quests/dashboard/NotificationStore';

    const authStore = useAuthStore();
    const notificationStore = useNotificationStore();
    const unreadCount = computed(() => (authStore.isUserLoggedIn ? notificationStore?.notificationCountData?.unread : loggedOutNotifications.length));
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/partials/z-index";
@import "@/sass/partials/breakpoints";
@import "@/sass/design-system/variables";

.notifications-bell-wrapper {
    position: relative;

    @media (max-width: $md-breakpoint-max-width) {
        margin-left: 23px;
    }

    i {
        position: relative;
        display: flex;
        align-items: center;
        color: $light-grey-blue;
        cursor: pointer;
        font-size: 19px;
        transition: color 200ms ease;

        &:hover {
            color: $baseWhite;
        }
    }

    .number {
        position: absolute;
        z-index: $header-p-z-index;
        top: -7px;
        right: -7px;
        display: flex;
        width: 16px;
        height: 16px;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background-color: $down-color;
        color: $baseBlack;
        font-size: 10px;
        font-weight: 700;
        pointer-events: none;

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 12px;
            height: 12px;
            border-radius: 8px;
            margin: auto;
            animation: pulse-me 1.7s linear infinite;
            background-color: $down-color;
            content: "";
            transform: scale(0.4);
            transform-origin: center center;
        }

        @keyframes pulse-me {
            0% {
                opacity: 0;
                transform: scale(0.3);
            }

            50% {
                opacity: 0.5;
            }

            60% {
                opacity: 0.3;
            }

            100% {
                opacity: 0;
                transform: scale(3);
            }
        }
    }
}
</style>
