<template>
    <div v-if="isMobileOrTablet">
        <div class="bell-wrapper"
             @click="handleNotificationBellMobileClick">
            <NotificationBell />
        </div>
        <NotificationsMobileModal v-if="mobileModalOpen"
                                  :loadMoreNotifications="loadMoreNotifications"
                                  :closeMobileModal="closeMobileModal" />
    </div>
    <div v-else
         ref="notificationBellRef"
         :aria-label="getTrans('header.notifications', 'Notifications')"
         class="bell-wrapper"
         @click="fetchNotifications();">
        <div>
            <NotificationBell />
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        h, onMounted, ref, watch,
    } from 'vue';
    import useTippyPopup from '../../../../../composables/useTippyPopup';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { useNotificationStore } from '@/js/store/quests/dashboard/NotificationStore';
    import NotificationsDesktopTippyContent from './NotificationsDesktopTippyContent.vue';
    import NotificationsMobileModal from './NotificationsMobileModal.vue';
    import NotificationBell from './NotificationBell.vue';
    import { getTrans } from '@/js/helpers/getTrans';

    const authStore = useAuthStore();
    const notificationStore = useNotificationStore();

    const mobileModalOpen = ref(false);

    const { isMobileOrTablet } = useDeviceSize();

    const openMobileModal = () => {
        mobileModalOpen.value = true;
    };

    const closeMobileModal = () => {
        mobileModalOpen.value = false;
    };

    const loadMoreNotifications = async () => {
        await notificationStore.fetchMoreNotifications();
        await notificationStore.fetchNotificationCount();
    };

    const fetchNotifications = async () => {
        await notificationStore.fetchNotifications();
        await notificationStore.fetchNotificationCount();
    };

    const handleNotificationBellMobileClick = () => {
        fetchNotifications();
        openMobileModal();
    };

    const notificationBellRef = ref();

    useTippyPopup(notificationBellRef, {
        content: h(NotificationsDesktopTippyContent, {
            loadMoreNotifications,
        }),
        interactive: true,
        arrow: true,
        placement: 'bottom',
        trigger: 'click',
        theme: 'base-dark',
    });

    onMounted(() => {
        notificationStore.fetchNotificationCount();
    });

    watch(() => authStore.isUserLoggedIn, (isUserLoggedIn) => {
        if (!isUserLoggedIn) {
            notificationStore.$reset();
        }
    });
</script>

<style scoped lang="scss">
@import "@/sass/partials/breakpoints";

.bell-wrapper {
    margin-left: 16px;

    @media (max-width: $md-breakpoint) {
        margin-left: 0;
    }
}
</style>
