<template>
    <div class="slider mx-auto mx-md-auto main-section">
        <div class="slide"
             :class="{ active: currentSlide === 0 }">
            <div class="py-3">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="streak-fire mx-1">
                        <StreakFire :color="Colors.STREAK_FIRE_COLOR"
                                    :title="getTrans(
                                        'quests-dashboard.streakCount',
                                        'Streak Count')
                                        + ': ' + streakCompletedTotal" />
                    </div>
                    <span class="number-with-border text-center text-white mb-0">
                        {{ streakCompletedTotal }}
                    </span>
                </div>
                <div class="text-center text-block-wrapper">
                    <p class="mb-0 streak-text text-white">
                        <Translate translationKey="quests-dashboard.dayStreak"
                                   fallback="day streak" />!
                    </p>
                    <div class="d-flex align-items-center justify-content-center">
                        <p class="text-white mb-0">
                            <Translate translationKey="quests-dashboard.earnMoreByKeepingUp"
                                       fallback="Earn more Bits by keeping it up" />
                        </p>
                        <Tippy placement="bottom"
                               class="d-flex"
                               theme="tip-info"
                               trigger="mouseenter"
                               interactive
                               animation="fade"
                               @create="assignTippyButtonRole">
                            <template #default="attrs">
                                <i v-bind="attrs"
                                   class="fa-regular fa-circle-info"
                                   role="tooltip"
                                   :aria-label="getTrans('quests-dashboard.streakTooltip', 'Streak tooltip')"
                                   @click="tooltipStore.setStreakTippyEarnMoreTooltip(true)" />
                            </template>
                            <template #content>
                                <div class="info">
                                    <p class="mb-0">
                                        <Translate translationKey="quests-dashboard.completingAtLeast"
                                                   fallback="Completing at least 1 round per day grows your Streak and earns you a Reward.
                                                   Keeping your Streak continued for 7 days earns you a special Bonus! Skipping a day zeroes your Streak." />
                                    </p>
                                </div>
                            </template>
                        </Tippy>
                        <TippyMobile name="streakTippyEarnMore">
                            <div class="info">
                                <p class="mb-0">
                                    <i class="fa-regular fa-circle-question mr-1" />
                                    <Translate translationKey="quests-dashboard.completingAtLeast"
                                               fallback="Completing at least 1 round per day grows your Streak and earns you a Reward.
                                               Keeping your Streak continued for 7 days earns you a special Bonus! Skipping a day zeroes your Streak." />
                                </p>
                            </div>
                        </TippyMobile>
                    </div>
                    <div class="my-3 share-tippy">
                        <ShareTippy v-if="streakCompletedTotal > 0 && referralToken"
                                    :shareUrl="`https://stude.co/a/${referralToken}`"
                                    :shareText="`${getTrans('quests-dashboard.shareTippyShareText',
                                                            'This has to be the easiest way I\'ve found to learn crypto & earn actual rewards. I\'m already on Streak')} ${streakCompletedTotal}! 🚀\n\n#BitDegree #Rewards`" />
                        <RouterLink v-else
                                    :to="{ name: 'missions' }"
                                    class="button"
                                    @click="closeStreak">
                            <button class="quiz-button--correct px-3">
                                <Translate translationKey="quests-dashboard.startStreak"
                                           fallback="START STREAK" />
                            </button>
                        </RouterLink>
                    </div>
                </div>
            </div>
        </div>
        <div class="slide"
             :class="{ active: currentSlide === 1 }">
            <div class="py-3">
                <div class="frozen-icon-wrapper">
                    <FrozenIcon color="#9e7ae6" />
                </div>
                <div class="text-center">
                    <p class="mb-0 streak-text text-white">
                        <Translate translationKey="quests-dashboard.youOwn"
                                   fallback="You own" /> x{{ streakBreakNumber }}
                    </p>
                    <div class="d-flex align-items-center justify-content-center">
                        <p class="text-white mb-0">
                            <Translate translationKey="quests-dashboard.streakBreak"
                                       fallback="Streak Break"
                                       :isPlural="streakBreakNumber !== 1" />
                        </p>
                        <tippy placement="bottom"
                               theme="tip-info"
                               class="d-flex"
                               trigger="mouseenter"
                               interactive
                               animation="fade"
                               @click="tooltipStore.setStreakBreaksTooltip(true)"
                               @create="assignTippyButtonRole">
                            <template #default="attrs">
                                <i v-bind="attrs"
                                   class="fa-regular fa-circle-info"
                                   role="tooltip"
                                   :aria-label="getTrans('quests-dashboard.streakBreakTooltip', 'Streak break tooltip')" />
                            </template>
                            <template #content>
                                <div class="info">
                                    <p class="mb-0">
                                        <Translate translationKey="quests-dashboard.eachBreakProtects"
                                                   fallback="Each Break protects your Streak from resetting for up to 3 days. Save your Breaks for emergency days." />
                                    </p>
                                </div>
                            </template>
                        </tippy>
                        <TippyMobile name="streakBreaks">
                            <div class="info">
                                <p class="mb-0">
                                    <i class="fa-regular fa-circle-question mr-1" />
                                    <Translate translationKey="quests-dashboard.eachBreakProtects"
                                               fallback="Each Break protects your Streak from resetting for up to 3 days. Save your Breaks for emergency days or recover it by completing a Mission." />
                                </p>
                            </div>
                        </TippyMobile>
                    </div>
                    <div v-if="streakBreakNumber <= STREAK_BREAK_TOTAL_COUNT"
                         class="my-3">
                        <RouterLink :to="{ name: 'missions' }"
                                    class="button"
                                    @click="closeStreak">
                            <button class="quiz-button--correct px-3">
                                <Translate v-if="streakBreakNumber < STREAK_BREAK_TOTAL_COUNT"
                                           translationKey="quests-dashboard.completeMissionToRecover"
                                           fallback="Complete Mission to Recover" />
                                <Translate v-if="streakBreakNumber === STREAK_BREAK_TOTAL_COUNT"
                                           translationKey="quests-dashboard.startGaining"
                                           fallback="Start gaining" />
                            </button>
                        </RouterLink>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow left-arrow"
             @click="prevSlide">
            <i class="fa-solid fa-caret-left" />
        </div>
        <div class="arrow right-arrow"
             @click="nextSlide">
            <i class="fa-solid fa-caret-right" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, watchEffect } from 'vue';
    import { Tippy } from 'vue-tippy';
    import ShareTippy from '@/js/quests/dashboard/components/ShareTippy.vue';
    import StreakFire from '@/js/quests/dashboard/svg/StreakFire.vue';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import TippyMobile from '@/js/quests/global/widgets/tooltips/TippyMobile.vue';
    import { useTooltipStore } from '@/js/store/quests/game/modules/TippyMobileStore';
    import { getTrans } from '@/js/helpers/getTrans';
    import { useNavigationStore } from '@/js/store/quests/dashboard/NavigationStore';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';
    import { Colors } from '@/js/quests/dashboard/constants/colors';
    import { MissionsSectionNameEnum } from '@/js/quests/dashboard/types/enums';
    import { HtmlIds } from '@/js/quests/dashboard/constants/htmlIds';
    import { scrollToSection } from '@/js/helpers/scrollHandler';
    import { assignTippyButtonRole } from '@/js/helpers/tippyManager';
    import FrozenIcon from '@/js/quests/dashboard/svg/FrozenIcon.vue';

    const { isMobileOrTablet } = useDeviceSize();

    const props = defineProps({
        streakCompletedTotal: {
            type: Number,
            required: true,
        },
        streakBreakNumber: {
            type: Number,
            required: true,
        },
        closeTippy: {
            type: Function,
            required: true,
        },
    });

    const STREAK_BREAK_TOTAL_COUNT = 2;

    const navigationStore = useNavigationStore();
    const userStore = useUserStore();
    const tooltipStore = useTooltipStore();

    const currentSlide = ref(0);
    const referralToken = ref('');
    const numSlides = 2;

    const handleScrollToSection = (section: MissionsSectionNameEnum) => {
        const options = {
            delay: 0,
            scrollOffsetElementId: isMobileOrTablet.value ? HtmlIds.MOBILE_HEADER : null,
        };

        scrollToSection(section, options);
    };

    const closeStreak = () => {
        if (isMobileOrTablet.value) {
            navigationStore.showMobileStreak = false;
        } else {
            props.closeTippy();
        }

        handleScrollToSection(MissionsSectionNameEnum.WEB3_MISSIONS_SECTION);
    };

    const prevSlide = () => {
        currentSlide.value = (currentSlide.value === 0) ? numSlides - 1 : currentSlide.value - 1;
    };

    const nextSlide = () => {
        currentSlide.value = (currentSlide.value === numSlides - 1) ? 0 : currentSlide.value + 1;
    };

    watchEffect(() => {
        if (userStore.referralToken) {
            referralToken.value = userStore.referralToken;
        }
    });
</script>

<style scoped lang="scss">
@import "@/sass/missions-partials/z-index";
@import "@/sass/partials/variables";
@import "@/sass/design-system/fonts";
@import "@/sass/partials/breakpoints";

$border-color: #fe872c;
$border-offset: 3px;

.streak-text {
    font-family: $font-quicksand;
}

.number-with-border {
    font-family: $font-quicksand;
    font-size: 80px;
    line-height: 1;

    @media (max-width: $md-breakpoint) {
        font-size: 100px;
    }

    text-shadow:
        #{$border-offset} #{$border-offset} 0 $border-color,
        -#{$border-offset} #{$border-offset} 0 $border-color,
        #{$border-offset} -#{$border-offset} 0 $border-color,
        -#{$border-offset} -#{$border-offset} 0 $border-color,
        #{$border-offset} 0 0 $border-color,
        -#{$border-offset} 0 0 $border-color,
        0 #{$border-offset} 0 $border-color,
        0 -#{$border-offset} 0 $border-color;
}

.streak-fire {
    width: 45px;
}

.slider {
    position: relative;
    display: flex;
    width: 330px;
    min-height: 256px;
    align-items: center;
    justify-content: center;
    margin: 0 10px;

    @media (max-width: $md-breakpoint-max-width) {
        min-height: 240px;
    }
}

.slide {
    position: absolute;
    opacity: 0;
    transition: opacity 0.4s ease;

    .frozen-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        :deep(svg) {
            width: 72px;

            @media (max-width: $md-breakpoint) {
                width: 100px;
            }
        }
    }
}

.slide.active {
    z-index: $streak-slider-z-index;
    opacity: 1;
    transition-delay: 0.2s;
}

.arrow {
    position: absolute;
    cursor: pointer;

    i {
        color: #893cff;
        font-size: 35px;
    }
}

.left-arrow {
    left: 10px;
}

.right-arrow {
    right: 10px;
}

.text-block-wrapper {
    max-width: 260px;
}

.fa-circle-info {
    margin-left: 4px;
    cursor: pointer;
    font-size: 16px;
}
</style>
