<template>
    <RegisterBonusModal v-if="modalStore.modals.showRegisterBonus"
                        @close="closeRegisterBonusAndOpenIntroduction" />
    <IntroductionModal v-if="modalStore.modals.showIntroduction"
                       @close="closeIntroductionAndOpenGuildsSuggestions" />
    <GuildsSuggestionsModal v-if="modalStore.modals.showGuildsSuggestionsModal"
                            :isOpen="modalStore.modals.showGuildsSuggestionsModal"
                            @close="closeGuildsSuggestionsAndOpenDownloadApp" />
    <DownloadAppModal v-if="modalStore.modals.showDownloadAppModal"
                      :isOpen="modalStore.modals.showDownloadAppModal"
                      @close="closeDownloadAppAndOpenBrowserNotifications" />
    <BrowserNotificationsModal v-if="modalStore.modals.showBrowserNotificationsModal"
                               :isOpen="modalStore.modals.showBrowserNotificationsModal" />
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import RegisterBonusModal from '@/js/quests/dashboard/modals/notifications/RegisterBonusModal.vue';
    import DownloadAppModal from '@/js/quests/dashboard/modals/DownloadAppModal.vue';
    import GuildsSuggestionsModal from '@/js/quests/dashboard/pages/guilds/components/GuildsSuggestionsModal.vue';
    import IntroductionModal from '@/js/quests/dashboard/modals/notifications/IntroductionModal.vue';
    import BrowserNotificationsModal from '@/js/quests/dashboard/modals/BrowserNotificationsModal.vue';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { useOnboardingModalChain } from '@/js/quests/dashboard/composables/useOnboardingModalChain';
    import { useBrowserNotifications } from '@/js/quests/dashboard/composables/useBrowserNotifications';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';

    const modalStore = useModalStore();

    const { isMobileOrTablet } = useDeviceSize();
    const { isSubscribeAvailable } = useBrowserNotifications();

    const isSubscribeModalOpenable = computed(() => !isMobileOrTablet.value && isSubscribeAvailable.value);

    const {
        closeRegisterBonusAndOpenIntroduction,
        closeIntroductionAndOpenGuildsSuggestions,
        closeGuildsSuggestionsAndOpenDownloadApp,
        closeDownloadAppAndOpenBrowserNotifications,
    } = useOnboardingModalChain(isSubscribeModalOpenable.value);
</script>
