import { computed, ref } from 'vue';
import { initializeApp } from 'firebase/app';
import {
 getMessaging, getToken, onMessage, isSupported,
} from 'firebase/messaging';
import type { Messaging } from 'firebase/messaging';
import firebaseConfig from '@/js/quests/global/helpers/firebase/firebase.config';
import {
    getValueFromLocalStorage,
    removeValueFromLocalStorage,
    setValueToLocalStorage,
} from '@/js/quests/game/helpers/localStorageHelper';
import { useDashboardStore } from '@/js/store/quests/dashboard/DashboardStore';
import { getCookieValue, setCookie } from '@/js/helpers/cookieHelpers';

export const PermissionTypeEnum = {
    GRANTED: 'granted',
    DENIED: 'denied',
    DEFAULT: 'default',
};

const WORKER_CLIENT_URL = '/build/sw/firebaseMessagingSw.js';
const VAPID_KEY = import.meta.env.VITE_APP_FIREBASE_VAPID_KEY ?? '';

const messaging = ref<Messaging | null>(null);

const token = ref(null);
const currentPermission = ref(PermissionTypeEnum.DEFAULT);
const isSubscribed = ref(false);
const isInitialiseCompleted = ref(false);
const isSubscribeCompleted = ref(false);
const notification = ref(null);
const isFirebaseSupported = ref(false);

const COOKIE_KEY = 'notification-remind-later';
const COOKIE_DAYS = 14;
const isRemindLater = ref(!!getCookieValue(COOKIE_KEY));

const isPermissionDenied = computed(() => currentPermission.value === PermissionTypeEnum.DENIED);
const isPermissionGranted = computed(() => currentPermission.value === PermissionTypeEnum.GRANTED);
const isSubscribeAvailable = computed(() => !isSubscribed.value && !isPermissionDenied.value && !isRemindLater.value);

const FIREBASE_TOKEN_REQUESTED_KEY = 'firebaseTokenRequested';

async function initializeFirebaseMessaging() {
    try {
        const supported = await isSupported();
        if (!supported) {
            // eslint-disable-next-line no-console
            console.warn('Firebase Messaging is not supported in this browser.');

            return;
        }

        const firebaseApp = initializeApp(firebaseConfig);
        messaging.value = getMessaging(firebaseApp);

        onMessage(messaging.value, (payload) => {
            notification.value = payload.notification;
        });

        isFirebaseSupported.value = true;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error initializing Firebase Messaging:', error);
    }
}

async function retrieveToken() {
    const worker = await navigator.serviceWorker.register(WORKER_CLIENT_URL);

    return getToken(messaging.value, {
        vapidKey: VAPID_KEY,
        serviceWorkerRegistration: worker,
    });
}

async function handleSubscriptionStatus() {
    if (currentPermission.value !== PermissionTypeEnum.GRANTED) {
        // eslint-disable-next-line no-console
        console.error('Notification permission not granted.');

        return;
    }

    const currentToken = await retrieveToken();

    if (!currentToken) {
        // eslint-disable-next-line no-console
        console.error('No token available.');

        return;
    }

    token.value = currentToken;
    isSubscribed.value = true;
}

function requestNotificationPermission(): Promise<string> {
    return new Promise<string>((resolve) => {
        const permissionResult = Notification.requestPermission((result) => resolve(result));
        if (permissionResult && permissionResult.then) {
            permissionResult.then(resolve);
        }
    });
}

async function initialiseNotification() {
    try {
        isInitialiseCompleted.value = false;
        currentPermission.value = Notification.permission;
        await handleSubscriptionStatus();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error retrieving notification token on initialization:', error);
    } finally {
        isInitialiseCompleted.value = true;
    }
}

async function subscribeToNotifications() {
    try {
        isSubscribeCompleted.value = false;
        currentPermission.value = await requestNotificationPermission();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error while attempting to subscribe to notifications:', error);
    } finally {
        isSubscribeCompleted.value = true;
    }
}

const checkFirebaseTokenRequested = async (firebaseToken) => {
    const dashboardStore = useDashboardStore();

    const token = getValueFromLocalStorage(FIREBASE_TOKEN_REQUESTED_KEY);

    if (!token) {
        return false;
    }

    await dashboardStore.sendFireBaseToken(firebaseToken);

    removeValueFromLocalStorage(FIREBASE_TOKEN_REQUESTED_KEY);

    return true;
};

async function activateSubscription() {
    try {
        setValueToLocalStorage(FIREBASE_TOKEN_REQUESTED_KEY, 'true');
        await handleSubscriptionStatus();
        if (isSubscribed.value && !!token.value) {
            await checkFirebaseTokenRequested(token.value);
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error while subscribing to notifications:', error);
    }
}

const setRemindLater = () => {
    isRemindLater.value = true;

    if (!getCookieValue(COOKIE_KEY)) {
        setCookie(COOKIE_KEY, 'true', COOKIE_DAYS);
    }
};

initializeFirebaseMessaging();

export function useBrowserNotifications() {
    return {
        token,
        currentPermission,
        isPermissionDenied,
        isPermissionGranted,
        isRemindLater,
        isSubscribed,
        isInitialiseCompleted,
        isSubscribeCompleted,
        isFirebaseSupported,
        notification,
        initialiseNotification,
        subscribeToNotifications,
        activateSubscription,
        checkFirebaseTokenRequested,
        setRemindLater,
        isSubscribeAvailable,
    };
}
