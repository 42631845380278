<template>
    <div ref="sidebarGuildRef"
         class="position-relative">
        <RouterLink :to="{name: dashboardRoutesNames.GUILD_ROUTE_NAME, params: { guildSlug: guild.slug}}">
            <div class="my-guild"
                 :class="{ 'my-guild--active': isActive }"
                 @click="emit('guildClick', guild.slug)">
                <div class="my-guild__image">
                    <BaseSkeletonLoader v-if="isImageLoading"
                                        width="100%"
                                        height="100%" />
                    <img v-else
                         class="my-guild__image-img"
                         width="41"
                         height="41"
                         :src="asset_cdn(image.src)"
                         :alt="getTrans('quests-dashboard.titleGuild', `${guild.title} Guild`, { title: guild.title })"
                         :title="getTrans('quests-dashboard.titleGuild', `${guild.title} Guild`, { title: guild.title })">
                    <div v-if="guild.has_unseen_updates"
                         class="my-guild__notification-badge" />
                </div>
            </div>
        </RouterLink>
    </div>
</template>

<script setup lang="ts">
    import {
        computed, h, ref, watch, onBeforeMount,
    } from 'vue';
    import { dashboardRoutesNames } from '@/js/quests/dashboard/constants/dashboardRoutesNames';
    import { asset_cdn } from '@/js/helpers/helpers';
    import { getTrans } from '@/js/helpers/getTrans';
    import { preloadImage } from '@/js/helpers/preloadImage';
    import useTippyPopup from '@/js/composables/useTippyPopup';
    import SidebarGuildContent from '@/js/quests/dashboard/navigation/sidebar/SidebarGuildContent.vue';
    import { SidebarGuild } from '@/js/quests/dashboard/types/sidebar';
    import BaseSkeletonLoader from '@/js/components/BaseSkeletonLoader.vue';

    const props = defineProps<{
        guild: SidebarGuild,
        isActive: boolean,
    }>();

    const emit = defineEmits<{(e: 'guildClick', slug: string): void; }>();

    const sidebarGuildRef = ref();

    const guildTitle = computed(() => props.guild.title);
    const content = computed(() => h(SidebarGuildContent, {
        title: getTrans('quests-dashboard.titleGuild', `${guildTitle.value} Guild`, { title: guildTitle.value }),
    }));

    const isImageLoading = ref(true);
    const imageSrc = computed(() => asset_cdn(props.guild.logo.url, 41));
    const image = ref(null);

    onBeforeMount(() => {
        preloadImage(imageSrc.value);
    });

    watch(() => imageSrc.value, () => {
        isImageLoading.value = true;
        image.value = new Image();

        image.value.onload = () => {
            isImageLoading.value = false;
        };

        image.value.onerror = () => {
            isImageLoading.value = false;
        };

        image.value.src = imageSrc.value;
    }, { immediate: true });

    useTippyPopup(sidebarGuildRef, {
        theme: 'tip-info',
        placement: 'right',
        trigger: 'mouseenter',
        isContentReactive: true,
        content,
    });
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/variables";

.my-guild {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0 4px;

    .my-guild__image {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 45px;
        height: 45px;
        align-items: center;
        justify-content: center;
        border: 2px solid $gray900;
        border-radius: 14px;
        cursor: pointer;
        transform-origin: center;
        transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;

        .my-guild__image-img {
            width: 100%;
            border-radius: 12px;
        }

        &:hover {
            transform: scale(1.05);
        }
    }

    &.my-guild--active {
        .my-guild__image {
            border: 3px solid $brand600;
        }
    }

    .my-guild__notification-badge {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 14px;
        height: 14px;
        border: 2px solid #1c002f;
        border-radius: 100%;
        background-color: $down-color;
        color: $black;
        font-size: 11px;
        font-weight: 600;
    }
}
</style>
