import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';

const triggerAfterDelay = (
    callback: () => void,
    delay = 500,
) => {
    setTimeout(callback, delay);
};

export const useOnboardingModalChain = (isSubscribeAvailable: boolean) => {
    const modalStore = useModalStore();

    const closeRegisterBonusAndOpenIntroduction = () => {
        triggerAfterDelay(() => {
            modalStore.setIntroductionModal(true);
        });

        modalStore.setRegisterBonusModal(false);
    };

    const closeIntroductionAndOpenGuildsSuggestions = () => {
        triggerAfterDelay(() => {
            modalStore.setGuildsSuggestionsModal(true);
        });

        modalStore.setIntroductionModal(false);
    };

    const closeGuildsSuggestionsAndOpenDownloadApp = () => {
        triggerAfterDelay(() => {
            modalStore.setDownloadAppModal(true, true);
        });

        modalStore.setGuildsSuggestionsModal(false);
    };

    const closeDownloadAppAndOpenBrowserNotifications = () => {
        if (isSubscribeAvailable) {
            triggerAfterDelay(() => {
                modalStore.setBrowserNotificationsModal(true);
            });
        }

        modalStore.setDownloadAppModal(false);
    };

    return {
        closeRegisterBonusAndOpenIntroduction,
        closeIntroductionAndOpenGuildsSuggestions,
        closeGuildsSuggestionsAndOpenDownloadApp,
        closeDownloadAppAndOpenBrowserNotifications,
    };
};
