<template>
    <div class="tangible-reward-modal">
        <BaseModal v-if="isOpen"
                   :padding="isMobileOrTablet ? '40px 0 16px' : '40px 0 32px'"
                   maxWidth="720px"
                   maxHeight="600px"
                   hasScrollTopShadow
                   hasScrollBottomShadow
                   useClickOutsideClose
                   @close="handleClose">
            <template #header>
                <div class="tangible-reward-modal__header-container">
                    <div class="tangible-reward-modal__title">
                        {{ userStore.tangible?.questMissionTitle }}
                    </div>
                    <div class="tangible-reward-modal__subtitle">
                        <Translate translationKey="quests-dashboard.tangibleRewardModalSubtitle"
                                   :replacements="{ reward: userStore.tangible?.rewardTitle }"
                                   fallback="You've successfully cracked this Mission, and as a result, you've won :reward" /> 🎉
                    </div>
                </div>
            </template>
            <template #content>
                <div class="tangible-reward-modal__content">
                    <div v-if="isCompleted"
                         class="tangible-reward-modal__over">
                        <Translate translationKey="quests-dashboard.tangibleRewardClaimed"
                                   fallback="Tangible reward claimed" />
                    </div>
                    <div v-else-if="isExpired"
                         class="tangible-reward-modal__over">
                        <Translate translationKey="quests-dashboard.tangibleRewardExpired"
                                   fallback="Tangible reward expired" />
                    </div>
                    <template v-else>
                        <div class="tangible-reward-modal__info">
                            <p>To claim your prize, please provide your <b>full postal address</b> through this form, including:</p>
                            <ul>
                                <li><b>Street name</b></li>
                                <li><b>House/apartment number</b></li>
                                <li><b>Province/region</b></li>
                                <li><b>Country</b></li>
                                <li><b>Postal code</b></li>
                            </ul>
                            <p><b>Important Notes:</b></p>
                            <ul>
                                <li>The form must be filled out within <b>three days</b> of receiving this message.</li>
                                <li>If we don’t receive your complete details within this time, the prize will be passed on to another winner.</li>
                            </ul>
                            <p><b>Disclaimer:</b></p>
                            <p>
                                Deliveries are restricted to <b>Afghanistan, Belarus, Bhutan, Ascension Island, Yemen,
                                    Martinique, South Georgia and the South Sandwich Islands, South Sudan, Timor-Leste, Russia,
                                    Syria, Saint Helena, and Tristan da Cunha</b>.
                            </p>
                        </div>
                        <div>
                            <div class="tangible-reward-modal__input-title">
                                <Translate translationKey="quests-dashboard.fullName"
                                           fallback="Full name" />
                            </div>
                            <input v-model="name"
                                   type="text"
                                   class="tangible-reward-modal__input tangible-reward-modal__input--name"
                                   :placeholder="getTrans('quests-dashboard.enterFullName', 'Enter your full name')">
                        </div>
                        <div>
                            <div class="tangible-reward-modal__input-title">
                                <Translate translationKey="quests-dashboard.phoneNumber"
                                           fallback="Phone number" />
                            </div>
                            <input v-model="phone"
                                   type="tel"
                                   class="tangible-reward-modal__input tangible-reward-modal__input--phone"
                                   :class="!isPhoneValid ? 'tangible-reward-modal__input--error' : ''"
                                   :placeholder="getTrans('quests-dashboard.enterPhoneNumber', 'Enter your phone number')">
                        </div>
                        <div>
                            <div class="tangible-reward-modal__label-wrapper">
                                <div class="tangible-reward-modal__input-title">
                                    <Translate translationKey="quests-dashboard.fullPostalAddress"
                                               fallback="Full postal address" />
                                </div>
                            </div>
                            <textarea v-model="address"
                                      class="tangible-reward-modal__input tangible-reward-modal__input--text-area"
                                      cols="40"
                                      :placeholder="getTrans('quests-dashboard.reporting.enterFullPostalAddressHere', 'Enter your full postal address')"
                                      rows="4"
                                      maxlength="500" />
                        </div>
                        <div v-if="errorMessage"
                             class="error-message">
                            {{ errorMessage }}
                        </div>
                    </template>
                </div>
            </template>
            <template #footer>
                <div v-if="!isCompleted && !isExpired"
                     class="tangible-reward-modal__actions">
                    <BaseButton :variant="ButtonVariantEnum.PRIMARY"
                                :disabled="isSubmitDisabled"
                                @click="handleConfirm">
                        <Translate translationKey="quests-dashboard.submit"
                                   fallback="Submit" />
                    </BaseButton>
                </div>
            </template>
        </BaseModal>
    </div>
</template>

<script setup lang="ts">
    import {
        computed, ref,
    } from 'vue';
    import { ButtonVariantEnum } from '@/js/types/components/ButtonVariantEnum';
    import BaseModal from '@/js/components/BaseModal.vue';
    import BaseButton from '@/js/components/BaseButton.vue';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { getTrans } from '@/js/helpers/getTrans';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { useModalActive } from '@/js/composables/useModalActive';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';

    interface Props {
        isOpen: boolean;
    }
    withDefaults(defineProps<Props>(), {
        isOpen: false,
    });

    const modalStore = useModalStore();
    const userStore = useUserStore();
    const { isMobileOrTablet } = useDeviceSize();

    const errorMessage = ref(null);
    const name = ref('');
    const address = ref('');
    const phone = ref('');

    const isPhoneValid = computed(() => {
        if (!phone.value) {
            return true;
        }

        const phoneRegex = /^\+[1-9]\d{1,14}$/;

        return phoneRegex.test(phone.value);
    });

    const isSubmitDisabled = computed(() => !name.value || (!phone.value || !isPhoneValid.value) || address.value === '');

    function isTimePastDate(targetDateString: string) {
        const currentTime = new Date().getTime();
        const targetTime = new Date(targetDateString).getTime();

        return currentTime > targetTime;
    }

    const isExpired = computed(() => isTimePastDate(userStore.tangible?.expiresAt));
    const isCompleted = computed(() => !!userStore.tangible?.completedAt);

    const handleClose = () => {
        modalStore.setTangibleRewardClaimModal(false);
    };

    const onRewardDataSend = async () => {
        try {
            await userStore.submitTangibleRewardData(userStore.tangible.id, {
                name: name.value,
                address: address.value,
                phone: phone.value,
            });

            errorMessage.value = '';

            handleClose();
        } catch (error) {
            if (error.response?.data && error.response?.data.message) {
                errorMessage.value = error.response.data.message;
            }
        }
    };

    const handleConfirm = async () => {
        try {
            errorMessage.value = '';
            await onRewardDataSend();
        } catch (error) {
            errorMessage.value = error;
        }
    };

    useModalActive();
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";

.tangible-reward-modal {
    &__header-container {
        padding-right: 32px;
        padding-left: 32px;
        color: $baseWhite;
        text-align: center;
    }

    &__title {
        margin-bottom: 16px;
        font-size: 32px;
        font-weight: 900;
        line-height: 1;
    }

    &__subtitle {
        margin-bottom: 16px;
        font-size: 18px;
    }

    &__over {
        color: $baseWhite;
        text-align: center;
    }

    &__info {
        margin-top: 8px;
        font-size: 14px;

        ul {
            padding-left: 24px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 0 32px 20px;
        color: $baseWhite;
        gap: 16px;

        .error-message {
            color: $error600;
            font-size: 12px;
            line-height: 1.2;
            text-align: left;
        }
    }

    &__actions {
        display: flex;
        margin: 32px 32px 0;
        gap: 12px;

        @media (max-width: $md-breakpoint-max-width) {
            margin: 16px 16px 4px;
        }
    }

    &__label-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    &__input {
        width: 100%;
        padding: 6px 12px;
        border: 1px solid $blue800;
        border-radius: 10px;
        background: transparent;
        color: $baseWhite;
        font-size: 16px;
        outline: none;
        resize: none;
        scrollbar-color: transparent transparent;
        scrollbar-width: thin;
        transition: 0.2s ease-in-out;

        &::placeholder {
            color: $gray800;
        }

        &:focus {
            border: 1px solid $blue600;
        }

        &::-webkit-scrollbar-thumb {
            border: 5px solid $brand950;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &--text-area {
            height: 130px;
        }

        &--error {
            border: 1px solid $error600;
        }
    }

    &__input-title {
        margin-bottom: 6px;
        margin-left: 6px;
        color: $baseWhite;
    }
}
</style>
