<template>
    <div class="progress-bar">
        <div class="progress"
             :style="progressStyle" />
        <div class="progress-lines">
            <div v-for="i in MAX_STREAK"
                 :key="i"
                 class="progress-line"
                 :style="lineStyle(i)" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { MAX_STREAK } from '../../constants/streak';

    interface Props {
        streakCompleted?: number;
    }

    const props = withDefaults(defineProps<Props>(), {
        streakCompleted: 0,
    });

    const progressPercentage = computed(() => (props.streakCompleted / MAX_STREAK) * 100);
    const progressStyle = computed(() => ({
        '--progress-width': `${progressPercentage.value}%`,
    }));
    const lineStyle = (i: number): Record<string, string> => ({
        left: `${i * (100 / MAX_STREAK)}%`,
        height: '100%',
        width: '1px',
        position: 'absolute',
    });
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/design-system/variables";

.progress-bar {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 20px;
    border-radius: 10px;
    background-color: $star-rating-inactive;

    .progress-lines {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;

        .progress-line {
            background-color: $baseWhite;
        }

        .progress-line::before {
            content: "";
        }
    }

    .progress {
        width: 0;
        height: 100%;
        border-radius: unset;
        animation: load-progress 2s ease forwards;
        background-color: $primary500;
    }

    @keyframes load-progress {
        from {
            width: 0;
        }
        to {
            width: var(--progress-width, 0);
        }
    }
}
</style>
