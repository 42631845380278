<template>
    <div class="header-container">
        <MobileScreenIcon />
        <div class="center-logo">
            <a :href="route('index')"
               class="logo">
                <img :src="asset_cdn('assets/about-us/timeline-top.png', 20, 26)"
                     :alt="getTrans('quests-game.bitdegreeLogo', 'BitDegree logo')"
                     width="20"
                     height="26">
            </a>
        </div>
        <div class="d-flex align-items-center justify-content-center">
            <div v-if="!authStore.isUserLoggedIn"
                 @click="modalStore.setAuthModal(true)">
                <div class="streak-container-mobile">
                    <div class="streak-fire d-flex align-items-center justify-content-center">
                        <StreakFire :title="getTrans('quests-dashboard.connectToStreak', 'Connect to Streak')" />
                    </div>
                </div>
            </div>
            <NotificationBell />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { asset_cdn, route } from '@/js/helpers/helpers';
    import NotificationBell from '../../modals/notifications/header-notifications/NotificationContext.vue';
    import { getTrans } from '@/js/helpers/getTrans';
    import StreakFire from '../../svg/StreakFire.vue';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import MobileScreenIcon from '../../../../global/header/components/MobileScreenIcon.vue';

    const authStore = useAuthStore();
    const modalStore = useModalStore();
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/design-system/variables";

.header-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px 8px 16px; // padding-right increased for notification bell page flickering
}

.center-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    transform: translate(-50%, -50%);
}

.logo {
    img {
        width: 20px;
    }
}

.notification-bell {
    width: 65px;
    justify-content: end;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.streak-container-mobile {
    display: flex;
    min-width: 40px;
    min-height: 27px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: $primary500;
    cursor: pointer;
    font-size: 19px;

    .streak-fire {
        width: 15px;
        height: 15px;
    }
}
</style>
