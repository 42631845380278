<template>
    <transition name="dialog"
                appear>
        <div v-if="!modalStore.showExitAnimation"
             class="modal d-flex justify-content-center align-items-center modal-transition">
            <div class="modal-wrapper">
                <div class="modal-content overflow-hidden text-center"
                     @click.stop>
                    <div class="content-wrapper">
                        <div v-for="(item, index) in data"
                             :key="item.title"
                             class="content">
                            <div v-if="index === step"
                                 class="slide-in">
                                <div class="d-flex justify-content-center align-items-center position-relative">
                                    <img :src="asset_cdn(item.image)"
                                         class="intro-image"
                                         :alt="item.title"
                                         :title="item.title">
                                </div>
                                <div class="modal-step">
                                    {{ item.title }}
                                </div>
                                <div class="modal-text">
                                    <p v-if="item.subtitle">
                                        {{ item.subtitle }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="slider-controls text-center mx-auto d-flex justify-content-center align-items-center">
                            <div v-for="index in 3"
                                 :key="index"
                                 class="slider-dot"
                                 :class="{ active: index - 1 === step }"
                                 @click="goToSlide(index - 1)" />
                        </div>
                        <div class="d-block">
                            <div class="button-wrapper mx-auto">
                                <button v-focus
                                        class="mb-2"
                                        :class="isLastStep ? 'quiz-button--correct' : 'quiz-button--inverted text-uppercase'"
                                        @click="handleContinueButtonClick"
                                        @keyup.enter="handleContinueButtonClick">
                                    <template v-if="isLastStep">
                                        <Translate translationKey="quests-dashboard.startTheJourney"
                                                   fallback="Start The Journey!" />
                                    </template>
                                    <template v-else>
                                        <Translate translationKey="quests-dashboard.continue"
                                                   fallback="Continue" />
                                    </template>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import { asset_cdn } from '@/js/helpers/helpers';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { getTrans } from '@/js/helpers/getTrans';
    import { useModalActive } from '@/js/composables/useModalActive';

    const emit = defineEmits<{(e: 'close'): void; }>();

    const modalStore = useModalStore();

    const step = ref(0);

    const data = [
        {
            title: getTrans('quests-dashboard.exploreMissions', 'Explore Missions'),
            subtitle: getTrans('quests-dashboard.improveWeb3Skills', 'Improve your Web3 skills by completing Missions the fun way. Anytime. Anywhere.'),
            image: 'assets/missions/introduction-1.svg',
        },
        {
            title: getTrans('quests-dashboard.keepTheStreakUp', 'Keep The Streak Up'),
            subtitle: getTrans('quests-dashboard.practicingRoundsDaily', 'By practicing Rounds daily, build strong habits and get the maximum Bits.'),
            image: 'assets/missions/introduction-2.svg',
        },
        {
            title: getTrans('quests-dashboard.gainRealRewards', 'Gain Real Rewards'),
            subtitle: getTrans('quests-dashboard.collectBitsAndBoost', 'Collect Bits, boost your Degree and gain actual rewards!'),
            image: 'assets/missions/introduction-3.svg',
        },
    ];

    const isLastStep = computed(() => step.value === data.length - 1);

    const goToSlide = (index: number) => {
        step.value = index;
    };

    const handleContinueButtonClick = () => {
        if (isLastStep.value) {
            emit('close');
        } else {
            step.value += 1;
        }
    };

    useModalActive();
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/design-system/variables";

.modal-transition {
    .modal-wrapper {
        max-width: 450px;
        max-height: 550px;
    }

    .modal-content {
        min-height: 510px;
        padding: 16px 11px;
        overflow-y: auto;

        .modal-content-animated {
            overflow: hidden;
            max-height: 0;
            transition: max-height 1.4s ease-in-out;
        }

        .modal-content-animated.is-animated {
            max-height: 500px;
        }

        &::-webkit-scrollbar {
            width: 6px;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background-color: #814cff;
        }

        &::-webkit-scrollbar-track {
            background: $dark-theme-bg;
        }

        @media (max-width: 650px) {
            padding: 8px 16px 16px 16px;
        }
    }

    div {
        color: $baseWhite;
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
    }

    .content .slide-in {
        min-height: 325px;
    }

    .modal-text {
        padding: 0 8px;
        font-size: clamp(13px, 2vw, 0.85rem);
        font-weight: 500;
    }

    .intro-image {
        width: 100%;
        max-height: 210px;
    }

    .button-wrapper {
        max-width: 300px;

        .quiz-button--correct,
        .quiz-button--inverted {
            width: 100%;
            font-weight: 900 !important;
        }
    }

    .slider-controls {
        right: 10px;
        bottom: 10px;
        display: flex;
        padding-top: 21px;
        padding-bottom: 24px;
        justify-items: center;
    }

    .slider-dot {
        width: 12px;
        height: 12px;
        border: 1px solid $primary500;
        border-radius: 15px;
        margin: 0 4px;
        background-color: #6f48c5;
        cursor: pointer;
        transition: width 0.2s ease-in-out;

        &.active,
        &:hover {
            width: 25px;
        }
    }

    @media (max-width: 768px) {
        .modal-step {
            font-size: 24px;
        }

        .button-wrapper {
            max-width: none;
        }
    }
}
</style>
