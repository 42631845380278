<template>
    <div v-if="authStore.isLoading"
         class="d-flex justify-content-center align-items-center w-100 vh-100">
        <Loader :size="80"
                :thickness="6"
                :variant="LoaderVariantsEnum.DARK_PURPLE" />
    </div>
    <section>
        <DashboardBar v-if="dashboardBarStore.isBarVisible" />
        <Sidebar v-if="authStore.isUserFetchCompleted"
                 :isMovedDown="dashboardBarStore.isBarVisible"/>
        <AirdropSeasonNotification v-if="!authStore.isLoading && isAirdropSeasonNotification"
                                   :isMovedDown="dashboardBarStore.isBarVisible" />
        <Navigation v-if="!authStore.isLoading"
                    :isMovedDown="dashboardBarStore.isBarVisible && !isAirdropSeasonNotification" />
        <MobileFooter v-if="isMobileOrTablet" />
        <RouterView v-if="authStore.isUserFetchCompleted"
                    :key="$route.path" />
        <OnboardingModals />
        <HeartRecoveryModal v-if="modalStore.modals.showHeartRecovery"
                            :currentHeartCount="currentHeartCount"
                            :previousHeartCount="previousHeartCount" />
        <AuthModal v-if="modalStore.modals.showAuth || modalStore.modals.showWalletConnect" />
        <EmailVerifyModal v-if="!authStore.isLoading && modalStore.modals.showEmailVerify" />
        <CustomErrorModal v-if="modalStore.modals.showCustomErrorModal" />
        <MetamaskMobileAuthModal v-if="modalStore.modals.showMetamaskAuthModal" />
        <CoinbaseMobileAuthModal v-if="modalStore.modals.showCoinbaseAuthModal" />
        <BybitDealRewardClaimedNotificationModal v-if="modalStore.modals.showBybitDealRewardClaimed" />
        <BybitDealNotificationModal v-if="modalStore.modals.showBybitDeal" />
        <ExtensionConnectedModal v-if="modalStore.modals.showExtensionConnectedModal"
                                 :isOpen="modalStore.modals.showExtensionConnectedModal" />
    </section>
    <SubscribeSuccessModal v-if="subscribeModalStore.modals.showSubscribeSuccessModal" />
    <CompanionBubble v-if="!authStore.isLoading && !authStore.isUserLoggedIn"
                     isMissionsPage
                     :isRaised="isCompanionRaised" />
    <ForceEmailVerificationModal v-if="modalStore.modals.showForceEmailVerification" />
    <BackToGameFooter v-if="shouldShowBackToGameFooter"
                      class="d-none d-md-flex" />
    <BackToGameFooterMobile v-if="shouldShowBackToGameFooterMobile"
                            class="d-flex d-md-none" />
    <ConnectToGainFooter v-if="shouldShowConnectToGainFooter" />
    <ReturnToMobileAppModal v-if="modalStore.modals.showReturnToMobileAppModal" />
    <BrowserNotifications />
    <TangibleReward />
</template>

<script setup lang="ts">
    import {
        computed, defineAsyncComponent, onMounted, onUnmounted, ref, watch,
    } from 'vue';
    import { useRoute } from 'vue-router';
    import Loader from '../../components/Loader.vue';
    import Sidebar from './navigation/Sidebar.vue';
    import Navigation from './navigation/Navigation.vue';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { CompanionFooterRaisedEnum, NotificationModalTypeEnum } from './types/enums';
    import BackToGameFooter from './components/back-to-game-footer/BackToGameFooter.vue';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { useDashboardStore } from '@/js/store/quests/dashboard/DashboardStore';
    import HeartRecoveryModal from './modals/notifications/HeartRecoveryModal.vue';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import BackToGameFooterMobile from './components/back-to-game-footer/BackToGameFooterMobile.vue';
    import MobileFooter from './navigation/mobile/footer/MobileFooter.vue';
    import EmailVerifyModal from './modals/settings/EmailVerifyModal.vue';
    import CustomErrorModal from './modals/CustomErrorModal.vue';
    import ForceEmailVerificationModal from './modals/ForceEmailVerificationModal.vue';
    import { EventTypeEnum } from '@/js/enums/enums';
    import { Web3ProviderEnum } from '@/js/store/web3/types/enums';
    import { isMobileDevice } from '@/js/auth/helpers/deviceUtils';
    import MetamaskMobileAuthModal from '../../auth/modals/mobile/MetamaskMobileAuthModal.vue';
    import CoinbaseMobileAuthModal from '../../auth/modals/mobile/CoinbaseMobileAuthModal.vue';
    import { clearWalletAddressFromLocalStorage } from '@/js/auth/helpers/clearWalletAddressFromLocalStorage';
    import { useWeb3Store } from '@/js/store/web3/Web3Store';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';
    import { LoaderVariantsEnum } from '@/js/enums/loaderVariants';
    import ReturnToMobileAppModal from '../../auth/modals/mobile/ReturnToMobileAppModal.vue';
    import { deleteUrlParam } from '@/js/helpers/urlUtils';
    import { QuestQueryParamsEnum } from '../global/types/enums';
    import CompanionBubble from '../../global/top-menu/CompanionBubble.vue';
    import { useSubscribeModalStore } from '@/js/store/subscribe/SubscribeModalStore';
    import SubscribeSuccessModal from '../../subscribe/modals/SubscribeSuccessModal.vue';
    import DashboardBar from './components/dashboard-bar/DashboardBar.vue';
    import { useDashboardBarStore } from '@/js/store/quests/dashboard/DashboardBarStore';
    import ConnectToGainFooter from '@/js/quests/dashboard/components/connect-to-gain-footer/ConnectToGainFooter.vue';
    import { COOKIES_KEYS } from '@/js/quests/game/constants/cookiesKeys';
    import { getCookieValue } from '@/js/helpers/cookieHelpers';
    import BybitDealRewardClaimedNotificationModal
        from '@/js/quests/dashboard/modals/notifications/BybitDealRewardClaimedNotificationModal.vue';
    import BybitDealNotificationModal from '@/js/quests/dashboard/modals/notifications/BybitDealNotificationModal.vue';
    import {
        isReferralCodeStored,
        isReferralCodeInUrl,
        storeReferralCode,
    } from '@/js/quests/dashboard/helpers/referralLinkHelper';
    import AirdropSeasonNotification from '@/js/quests/dashboard/components/AirdropSeasonNotification.vue';
    import OnboardingModals from '@/js/quests/dashboard/components/OnboardingModals.vue';
    import BrowserNotifications from '@/js/quests/dashboard/components/BrowserNotifications.vue';
    import TangibleReward from '@/js/quests/dashboard/components/TangibleReward.vue';
    import { useExtensionConnected } from '@/js/quests/dashboard/composables/useExtensionConnected';
    import ExtensionConnectedModal from '@/js/quests/dashboard/modals/ExtensionConnectedModal.vue';

    const AuthModal = defineAsyncComponent(() => import('../../auth/AuthModal.vue'));

    const authStore = useAuthStore();
    const web3Store = useWeb3Store();
    const userStore = useUserStore();
    const dashboardStore = useDashboardStore();
    const modalStore = useModalStore();
    const subscribeModalStore = useSubscribeModalStore();
    const dashboardBarStore = useDashboardBarStore();
    const route = useRoute();
    const { isMobileOrTablet } = useDeviceSize();
    const { checkExtensionConnected } = useExtensionConnected();

    const isAirdropSeasonNotification = ref(true);
    const currentHeartCount = ref(0);
    const previousHeartCount = ref(0);

    const isCompanionRaised = computed(() => dashboardStore.isCompanionRaised.isMissionActive
        || dashboardStore.isCompanionRaised.isSelectedMissionFooterShown
        || dashboardStore.isCompanionRaised.isMissionsFooterShown);

    const isMission = computed(() => route.name === 'mission');

    const isActiveMission = computed(() => {
        const { activeUserRoundData, isLoadingActiveUserRoundData } = userStore;

        return !!activeUserRoundData && !isLoadingActiveUserRoundData;
    });

    const shouldShowBackToGameFooter = computed(() => isActiveMission.value && !isMobileOrTablet.value);
    const shouldShowBackToGameFooterMobile = computed(() => isActiveMission.value && isMobileOrTablet.value);
    const shouldShowConnectToGainFooter = computed(() => authStore.isUserFetchCompleted && !authStore.isUserLoggedIn && !isMission.value && isMobileOrTablet.value);

    const windowWidth = ref(window.innerWidth);

    const handleWindowResize = () => {
        windowWidth.value = window.innerWidth;
    };

    function getModalValueByNotification(notification) {
        switch (notification.type) {
        case NotificationModalTypeEnum.RegistrationRewardReceived:
            return 'RegisterBonus';
        case NotificationModalTypeEnum.HeartRecovered:
            currentHeartCount.value = notification.data.current_heart_count;
            previousHeartCount.value = notification.data.previous_heart_count;

            return 'HeartRecovery';
        case NotificationModalTypeEnum.BybitDealRewardClaimed:
            return 'BybitDealRewardClaimed';
        case NotificationModalTypeEnum.BybitDeal:
            return 'BybitDeal';
        default:
            return null;
        }
    }

    function openModalAndWaitForClose(modalValue) {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                modalStore[`set${modalValue}Modal`](true);

                const modalKey = modalValue === 'RegisterBonus'
                    ? 'showIntroduction'
                    : `show${modalValue.charAt(0).toUpperCase()}${modalValue.slice(1)}`;

                watch(() => modalStore.modals[modalKey], (newValue) => {
                    if (!newValue) {
                        resolve();
                    }
                });
            }, 1000);
        });
    }

    function handleNotification(notification) {
        const modalValue = getModalValueByNotification(notification);
        if (!modalValue) return;

        // eslint-disable-next-line consistent-return
        return openModalAndWaitForClose(modalValue);
    }

    const handleAuthType = async (authType: string) => {
        if (authType === Web3ProviderEnum.METAMASK || authType === Web3ProviderEnum.COINBASE) {
            web3Store.otpCode = await web3Store.fetchOtp();

            if (authType === Web3ProviderEnum.METAMASK) {
                modalStore.setMetamaskAuthModal(true);
            }

            if (authType === Web3ProviderEnum.COINBASE) {
                modalStore.setCoinbaseAuthModal(true);
            }
        }
    };

    watch(() => userStore.notifications, async (newNotifications) => {
        if (newNotifications !== null) {
            // eslint-disable-next-line no-restricted-syntax
            for (const notification of newNotifications) {
                // eslint-disable-next-line no-await-in-loop
                await handleNotification(notification);
            }
        }
    }, { immediate: true });

    watch(() => isActiveMission.value, () => {
        dashboardStore.setIsCompanion(isActiveMission.value, CompanionFooterRaisedEnum.IS_MISSION_ACTIVE);
    }, { immediate: true });

    onMounted(async () => {
        clearWalletAddressFromLocalStorage();

        await authStore.fetchUser();

        const queryParams = new URLSearchParams(window.location.search);
        const authType = queryParams.get(QuestQueryParamsEnum.TYPE);
        const isMobileApp = queryParams.get('is-mobile-app');

        if (isMobileApp) {
            web3Store.setIsMobileApp(true);
        }

        if (authType && isMobileDevice()) {
            await handleAuthType(authType);
            deleteUrlParam(QuestQueryParamsEnum.TYPE, queryParams);
        }

        window.addEventListener(EventTypeEnum.RESIZE, handleWindowResize);

        if (getCookieValue(COOKIES_KEYS.CUSTOM_ERROR_MODAL)) {
            modalStore.setCustomErrorModal(true);
        }

        if (authStore.isUserFetchCompleted && !authStore.isUserLoggedIn) {
            if (isReferralCodeInUrl()) {
                storeReferralCode();
            }

            if (isReferralCodeStored()) {
                authStore.isReferralCodeStored = true;
            }
        }

        checkExtensionConnected();
    });

    onUnmounted(() => {
        window.removeEventListener(EventTypeEnum.RESIZE, handleWindowResize);
    });
</script>
