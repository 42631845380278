<template>
    <div>
        <div class="d-flex align-items-center">
            <div v-if="shouldShowLoader"
                 class="stats-wrapper flex-container">
                <Loader :variant="LoaderVariantsEnum.WHITE" />
            </div>
            <template v-else>
                <div v-if="shouldDisplayStats"
                     class="stats-wrapper">
                    <div class="d-flex justify-content-between user-stats">
                        <RouterLink :to="{ name: dashboardRoutesNames.WALLET_ROUTE_NAME }"
                                    class="stats-bits">
                            <div class="d-flex align-items-center col-6">
                                <div>
                                    <img class="coin-logo"
                                         :src="asset_cdn('assets/missions/coin.svg')"
                                         :alt="'Bits: ' + bigNumberFormat(Number(userStore.bits) ?? 0)"
                                         :title="'Bits: ' + bigNumberFormat(Number(userStore.bits) ?? 0)">
                                </div>
                                <div class="ml-2 bits-wrapper position-relative">
                                    <div class="quests-stat-title">
                                        Bits
                                    </div>
                                    <div class="font-weight-bold stat-number text-center">
                                        <span>
                                            {{ bigNumberFormat(Number(userStore.bits) ?? 0) }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </RouterLink>
                        <RouterLink :to="{ name: dashboardRoutesNames.WALLET_ROUTE_NAME }"
                                    class="stats-bits">
                            <tippy ref="profileTippy"
                                   :placement="tooltipPlacement"
                                   trigger="mouseenter"
                                   theme="tip-info"
                                   interactive
                                   animation="fade"
                                   @create="assignTippyButtonRole">
                                <template #default="attrs">
                                    <div v-bind="attrs"
                                         class="info d-flex align-items-center col-6 profile-tooltip-open">
                                        <div class="position-relative">
                                            <img class="profile-image position-relative"
                                                 :src="getAvatar(userStore.avatarHash)"
                                                 alt="avatar">
                                            <i class="fa-solid fa-caret-down" />
                                        </div>
                                        <div class="ml-2">
                                            <div class="quests-stat-title">
                                                <Translate translationKey="quests-dashboard.degree"
                                                           fallback="Degree" />
                                            </div>
                                            <div class="font-weight-bold stat-number text-center">
                                                <Loader v-if="userStore.isLoading"
                                                        class="mt-1"
                                                        :variant="LoaderVariantsEnum.WHITE" />
                                                <template v-else>
                                                    <template v-if="userStore.degree?.current_degree">
                                                        <span>{{ userStore.degree.current_degree }}</span>
                                                        <span class="degree-symbol ml-1">°</span>
                                                    </template>
                                                    <span v-else>0<span class="degree-symbol ml-1">°</span></span>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #content>
                                    <div class="profile-tooltip">
                                        <RouterLink :to="{ name: dashboardRoutesNames.FRIENDS_ROUTE_NAME }"
                                                    @click="toggleTooltip">
                                            <div class="profile-tooltip-item d-flex align-items-center justify-content-start">
                                                <Translate translationKey="quests-dashboard.inviteFriend"
                                                           fallback="Invite to Earn" />
                                                <div class="ml-2">
                                                    <img width="16"
                                                         :src="asset_cdn('assets/missions/coin.svg')"
                                                         :alt="getTrans('quests-dashboard.inviteFriend', 'Invite to Earn')"
                                                         :title="getTrans('quests-dashboard.inviteFriend', 'Invite to Earn')">
                                                </div>
                                            </div>
                                        </RouterLink>
                                        <RouterLink :to="{ name: dashboardRoutesNames.GUILDS_STUDIO_ROUTE_NAME }"
                                                    @click="toggleTooltip">
                                            <div class="profile-tooltip-item">
                                                Guilds Studio
                                            </div>
                                        </RouterLink>
                                        <RouterLink :to="{ name: dashboardRoutesNames.SETTINGS_ROUTE_NAME }"
                                                    @click="toggleTooltip">
                                            <div class="profile-tooltip-item">
                                                <Translate translationKey="quests-dashboard.settings"
                                                           fallback="Settings" />
                                            </div>
                                        </RouterLink>
                                        <div class="profile-tooltip-item logout"
                                             @click.prevent="handleLogoutClick">
                                            <Translate translationKey="quests-dashboard.logOut"
                                                       fallback="Log out" />
                                        </div>
                                    </div>
                                </template>
                            </tippy>
                        </RouterLink>
                    </div>
                </div>
                <button v-else
                        class="quiz-button--correct"
                        @click="modalStore.setAuthModal(true)">
                    <Translate translationKey="quests-dashboard.connectToGain"
                               fallback="Connect To Gain" />
                </button>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref, computed } from 'vue';
    import { Tippy } from 'vue-tippy';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { asset_cdn, bigNumberFormat } from '@/js/helpers/helpers';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { getTrans } from '@/js/helpers/getTrans';
    import { getAvatar } from '@/js/helpers/avatars';
    import Loader from '../../../components/Loader.vue';
    import { LoaderVariantsEnum } from '@/js/enums/loaderVariants';
    import { dashboardRoutesNames } from '../constants/dashboardRoutesNames';
    import { assignTippyButtonRole } from '@/js/helpers/tippyManager';

    const authStore = useAuthStore();
    const userStore = useUserStore();
    const modalStore = useModalStore();

    const profileTippy = ref(null);

    const shouldShowLoader = computed(() => userStore.isLoading
        || authStore.isLoading
        || !authStore.isUserFetchCompleted);

    const shouldDisplayStats = computed(() => !userStore.isLoading
        && !authStore.isLoading
        && authStore.isUserLoggedIn
        && authStore.isUserFetchCompleted);

    async function fetchUserDataAndAvatar() {
        if (authStore.isUserLoggedIn) {
            try {
                await userStore.fetchQuestsUserData();
                modalStore.setAuthModal(false);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(`Error fetching data: ${error}`);
            }
        }
    }

    const tooltipPlacement = computed(() => {
        const isFirefoxBrowser = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

        // Return 'bottom' for Firefox, because 'bottom-end' doesn't work on Firefox
        return isFirefoxBrowser ? 'bottom' : 'bottom-end';
    });

    const toggleTooltip = () => {
        profileTippy.value.hide();
    };

    const handleLogoutClick = () => {
        authStore.logout();
    };

    onMounted(() => {
        fetchUserDataAndAvatar();
    });
</script>

<style lang="scss">
.user-stats {
    .stats-bits {
        &:hover {
            text-decoration: none;
        }

        .tippy-box {
            border: none;
        }
    }
}
</style>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/design-system/variables";
@import "@/js/quests/global/sass/mixins/_buttons";

.quiz-button--correct {
    @include alt-primary-button;
}

.flex-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.stats-wrapper {
    min-width: 220px;
    height: 46px;
    color: red;

    .user-stats {
        padding: 3px 0;

        .stats-bits {
            img,
            .stat-number,
            .stat-number span {
                transition: 0.2s ease-in-out;
            }

            &:hover {
                img,
                .profile-image {
                    filter: brightness(118%);
                    transform: scale(1.05);
                }

                .stat-number {
                    transform: scale(1.05);

                    span {
                        color: #e5e5e5;
                    }
                }
            }

            .bits-wrapper {
                color: $baseWhite;
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.profile-tooltip-open {
    .stat-number,
    .stat-number span,
    .profile-image {
        transition: 0.2s ease-in-out;
    }

    i {
        position: absolute;
        right: 14px;
        bottom: -9px;
        color: #9b96ff;
        font-size: 12px;
    }

    .degree-symbol {
        font-weight: 200;
    }
}

.profile-tooltip {
    min-width: 145px;
    border: 1px solid $jacarta;
    border-radius: 14px;
    background-color: $blue950;

    a {
        text-decoration: none;
    }

    .profile-tooltip-item {
        padding: 10px 12px 10px 15px;
        border-bottom: 1px solid $jacarta;
        color: $baseWhite;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        opacity: 0.8;
        transition: 0.2s ease-in-out;

        &:hover {
            opacity: unset;
        }

        &.logout {
            border-bottom: none;
        }
    }
}
</style>
