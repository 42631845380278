<template>
    <div class="back-to-game-footer">
        <div class="back-to-game-container d-flex justify-content-between align-items-center col-12">
            <div class="col-6 d-none d-sm-block">
                <div class="title">
                    🚨 <Translate translationKey="quests-dashboard.youreOnActiveMission"
                                  fallback="You're on active Mission right now:" /> 🚨
                </div>
                <div class="d-flex align-items-center justify-content-center">
                    <div class="prize">
                        {{ userStore.activeUserRoundData?.quest_round?.quest_mission?.title }}
                    </div>
                </div>
            </div>
            <div class="col-3 d-flex align-items-center justify-content-center">
                <div class="time"
                     :class="{ 'animated': animate, 'text-danger': remainingTime <= 30 }">
                    {{ formatSeconds(remainingTime) }}
                </div>
            </div>
            <div class="col-3 d-flex align-items-center justify-content-center">
                <button class="quiz-button--correct"
                        @click="getUserBackToGame">
                    <Translate translationKey="quests-dashboard.backToMission"
                               fallback="Back to mission" /> ➡
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        ref, watch,
    } from 'vue';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { closeCurtains } from '../../../game/animations/closeCurtains';
    import { questsRoutes } from '@/js/constants/questsRoutes';

    const authStore = useAuthStore();
    const modalStore = useModalStore();
    const userStore = useUserStore();

    const remainingTime = ref(userStore.activeUserRoundData?.remaining_time);
    const animate = ref(false);
    let timerId = 0;
    let endTime: Date;

    const animateShake = () => {
        animate.value = true;
        setTimeout(() => {
            animate.value = false;
        }, 300);
    };

    watch(
        () => remainingTime.value,
        (newValue) => {
            if (newValue && newValue <= 30) {
                animateShake();
            }
        },
    );

    const getUserBackToGame = () => {
        if (authStore.user?.must_verify_email) {
            modalStore.setForceEmailVerificationModal(true);
        } else {
            closeCurtains(userStore.activeUserRoundData?.quest_round?.content?.url || questsRoutes.MISSION_ROUTE);
        }
    };

    const formatSeconds = (time: number) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - (hours * 3600)) / 60);
        const seconds = time - (hours * 3600) - (minutes * 60);

        return [hours, minutes, seconds].map((v) => `0${v}`.slice(-2)).join(':');
    };

    const startCountdown = () => {
        const startTime = new Date();
        const endTimestamp = startTime.getTime() + remainingTime.value * 1000;
        endTime = new Date(endTimestamp);

        timerId = window.setInterval(() => {
            const now = new Date();
            const remainingTimeMs = endTime.getTime() - now.getTime();

            if (remainingTimeMs > 0) {
                remainingTime.value = Math.floor(remainingTimeMs / 1000);
            } else {
                window.clearInterval(timerId);
                remainingTime.value = 0;

                if (userStore.activeUserRoundData) {
                    userStore.cancelRoundByTimeEnd();
                }

                userStore.fetchActiveUserRoundData();
            }
        }, 1000);
    };

    watch(
        () => userStore.activeUserRoundData,
        (newRoundData) => {
            if (newRoundData?.started_at) {
                remainingTime.value = newRoundData?.remaining_time || 0;
                startCountdown();
            }
        },
        { immediate: true },
    );
</script>

<style scoped lang="scss">
@import "@/sass/missions-partials/z-index";
@import "@/sass/partials/variables";
@import "@/sass/partials/breakpoints";
@import "@/sass/design-system/fonts";
@import "@/sass/design-system/variables";

.back-to-game-footer {
    position: fixed;
    z-index: $dashboard-navigation-wrapper-z-index;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 75px;
    align-items: center;
    justify-content: space-around;
    padding: 16px;
    background-color: $baseBlack;

    .back-to-game-container {
        max-width: $lg-laptop-breakpoint;
    }

    .title {
        color: #f2c82c;
        font-family: $font-quicksand;
        font-size: 19px;
        text-align: center;
        text-transform: uppercase;
    }

    .text-danger {
        color: #fe4242;
    }

    .prize {
        color: $baseWhite;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
    }

    .time {
        color: $baseWhite;
        font-family: $font-quicksand;
        font-size: 40px;
        font-weight: 700;
        line-height: 1;
    }

    @media (max-width: 1023px) {
        .title {
            font-size: 16px;
        }
        .prize {
            font-size: 11px;
        }
        .time {
            font-size: 24px;
        }
    }

    .quiz-button--correct {
        padding: 0 32px;
        border: 1px solid #f2c82c;
        background-color: #f2c82c;
        box-shadow: 3px 4px 0 0 #8e7618;
        color: #514e06;
        font-weight: 700;
        text-transform: uppercase;

        &:hover {
            background-color: #ffd331;
        }
    }

    @keyframes shake {
        0% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(10px);
        }
        50% {
            transform: translateX(-10px);
        }
        75% {
            transform: translateX(10px);
        }
        100% {
            transform: translateX(0);
        }
    }

    .animated {
        animation: shake 0.3s linear;
    }
}
</style>
