<template>
    <div ref="contentRef"
         class="scroll-shadow-content-wrapper">
        <slot name="content" />
        <slot name="shadow-component"
              :show="!isScrolled" />
    </div>
</template>

<script setup lang="ts">
    import {
        ref, onMounted, onUnmounted, computed,
    } from 'vue';
    import { useEventListener } from '@/js/composables/useEventListener';

    const contentRef = ref<HTMLDivElement>();
    const isScrolled = ref(false);
    const props = defineProps<{maxHeight: string}>();

    const maxHeight = computed(() => props.maxHeight);

    const mutationObserver = ref(null);

    const setScrollValueOfMountedComponent = () => {
        isScrolled.value = Math.ceil(contentRef.value.scrollTop + contentRef.value.clientHeight) >= contentRef.value.scrollHeight;
    };

    const connectMutationObserver = () => {
        mutationObserver.value = new MutationObserver(setScrollValueOfMountedComponent);

        mutationObserver.value.observe(contentRef.value, {
            attributes: true,
            childList: true,
            characterData: true,
            subtree: true,
        });
    };

    const disconnectMutationObserver = () => {
        mutationObserver.value.disconnect();
    };

    onMounted(async () => {
        setScrollValueOfMountedComponent();
        connectMutationObserver();
    });

    onUnmounted(() => {
        disconnectMutationObserver();
    });

    const handleScroll = (event) => {
        const searchDropdownInner = event.target;
        const scrollHeight = searchDropdownInner.scrollHeight;
        const visibleHeight = searchDropdownInner.clientHeight;
        const scrollTop = searchDropdownInner.scrollTop;
        isScrolled.value = Math.ceil(scrollTop + visibleHeight) >= scrollHeight;
    };

    useEventListener(contentRef, 'scroll', handleScroll);
</script>

<style scoped lang="scss">

.scroll-shadow-content-wrapper {
    max-height: v-bind(maxHeight);
    border-radius: 0;
    overflow-y: auto;
    scrollbar-width: none;
}
</style>
