<template>
    <section class="dashboard-navigation-wrapper"
             :class="{
                 'hidden': isHidden,
                 'dashboard-navigation-wrapper--moved-down': isMovedDown
             }">
        <MobileHeader v-if="showMobileHeader" />
        <TabletHeader v-if="showTabletHeader"
                      @setMenuCardToggleRef="setMenuCardToggleRef" />
        <DesktopHeader v-if="showDesktopHeader" />
        <MenuCard v-if="navigationStore.showMenuCard"
                  ref="menuCardRef" />
    </section>
    <ShareTooltipMobile />
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import MobileHeader from './mobile/MobileHeader.vue';
    import DesktopHeader from './desktop/DesktopHeader.vue';
    import ShareTooltipMobile from '../../global/widgets/tooltips/ShareTooltipMobile.vue';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { useNavigationStore } from '@/js/store/quests/dashboard/NavigationStore';
    import { useEventListener } from '@/js/composables/useEventListener';
    import TabletHeader from './tablet/TabletHeader.vue';
    import MenuCard from './menu/MenuCard.vue';
    import { useClickOutside } from '@/js/composables/useClickOutside';
    import { BreakpointsEnum } from '@/js/enums/breakpoints';
    import { LAPTOP_WIDTH_PX } from '../constants';

    defineProps<{ isMovedDown: boolean}>();

    const navigationStore = useNavigationStore();
    const authStore = useAuthStore();
    const { windowWidth, isMobileOrTablet } = useDeviceSize();

    const showMobileHeader = computed(() => isMobileOrTablet.value && authStore.isUserFetchCompleted);
    const showTabletHeader = computed(() => windowWidth.value <= LAPTOP_WIDTH_PX && windowWidth.value > BreakpointsEnum.TABLET_WIDTH_IN_PX);
    const showDesktopHeader = computed(() => windowWidth.value > LAPTOP_WIDTH_PX);
    const menuCardRef = ref(null);
    const menuCardToggleRef = ref(null);
    const isScrollingUp = ref(false);
    const lastScrollPosition = ref(0);
    const isHidden = computed(() => (showTabletHeader.value ? !isScrollingUp.value : false));

    const setMenuCardToggleRef = (ref) => {
        menuCardToggleRef.value = ref.value;
    };

    const closeMenuCard = () => {
        navigationStore.showMenuCard = false;
    };

    useClickOutside(menuCardRef, closeMenuCard, [menuCardToggleRef]);

    const checkScrollDirection = () => {
        const scrollPosition = document.documentElement.scrollTop;

        if (scrollPosition > lastScrollPosition.value) {
            isScrollingUp.value = false;
            navigationStore.showMenuCard = false;
        } else {
            isScrollingUp.value = true;
        }

        lastScrollPosition.value = scrollPosition <= 0 ? 0 : scrollPosition;
    };

    useEventListener(window, 'scroll', checkScrollDirection);
</script>

<style scoped lang="scss">
@import "@/sass/partials/breakpoints";
@import "@/sass/partials/variables";
@import "@/sass/design-system/variables";
@import "@/sass/missions-partials/z-index";

.dashboard-navigation-wrapper {
    position: relative;
    padding: 0 32px;
    margin-right: auto;
    margin-bottom: 48px;
    margin-left: auto;

    @media (max-width: 1024px) {
        position: sticky;
        z-index: $navigation-section-z-index;
        top: 0;
        padding: 0 16px;
        border-bottom: 1px solid $dark-theme-border;
        margin-bottom: 32px;
        background-color: $brand950;
        transition: top 0.3s ease-in-out;

        &.hidden {
            top: -82px;
        }
    }

    @media (max-width: $md-breakpoint-max-width) {
        z-index: $dashboard-navigation-wrapper-z-index;
        padding: 0;
    }

    &--moved-down {
        top: $dashboard-bar-height;
    }
}
</style>
