<template>
    <div class="notifications-modal-mobile-wrapper">
        <span class="close-button"
              @click="props.closeMobileModal">
            &times;
        </span>
        <div class="title">
            <Translate translationKey="quests-dashboard.notifications"
                       fallback="Notifications" />
        </div>
        <!-- 83px is close-button and title height -->
        <ScrollShadowObserver maxHeight="calc(100% - 83px)">
            <template #content>
                <div>
                    <PromoNotificationRow @click="props.closeMobileModal" />
                    <div v-for="notification in notifications"
                         :key="notification?.title">
                        <NotificationRow :notification="notification"
                                         @click="props.closeMobileModal" />
                    </div>
                    <NotificationsFooter :loadMoreNotifications="loadMoreNotifications" />
                </div>
            </template>
            <template #shadow-component="shadowComponentProps">
                <ScrollIndicator :show="shadowComponentProps.show" />
            </template>
        </ScrollShadowObserver>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import NotificationsFooter from './NotificationsFooter.vue';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { loggedOutNotifications, useNotificationStore } from '@/js/store/quests/dashboard/NotificationStore';
    import NotificationRow from './NotificationRow.vue';
    import ScrollIndicator from '../../../../../components/ScrollShadowObserver/ScrollIndicator.vue';
    import ScrollShadowObserver from '../../../../../components/ScrollShadowObserver/ScrollShadowObserver.vue';
    import { useModalActive } from '@/js/composables/useModalActive';
    import PromoNotificationRow
        from '@/js/quests/dashboard/modals/notifications/header-notifications/PromoNotificationRow.vue';

    interface NotificationsMobileModalProps {
        loadMoreNotifications: () => void;
        closeMobileModal: () => void;
    }
    const props = defineProps<NotificationsMobileModalProps>();

    const authStore = useAuthStore();
    const notificationStore = useNotificationStore();
    const modalStore = useModalStore();

    const storeNotifications = computed(() => notificationStore.notificationData?.data?.map((notification) => ({
        url: notification?.data.url,
        title: notification?.data.title,
        subtitle: notification?.data.subtitle,
        image_path: notification?.data.image_path,
        read_at: notification.read_at,
        type: notification?.type,
    })));

    const loggedOutNotificationsEnhanced = computed(() => loggedOutNotifications.map((x) => ({ ...x, onClick: x.url ? null : () => modalStore.setAuthModal(true) })));

    const notifications = computed(() => (authStore.isUserLoggedIn ? storeNotifications.value : loggedOutNotificationsEnhanced.value));

    useModalActive();
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/partials/z-index";
@import "@/sass/design-system/variables";

.notifications-modal-mobile-wrapper {
    position: fixed;
    z-index: $modal-header-z-index;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $purple-dark;

    .close-button {
        padding: 12px;
        color: $baseWhite;
        cursor: pointer;
        font-size: 32px;
        font-weight: 500;
    }

    .title {
        padding-bottom: 12px;
        padding-left: 12px;
        border-bottom: 1px solid $purple-active;
        color: $baseWhite;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
    }

    .notifications-wrapper {
        width: 100%;
        height: calc(100% - 83px);
        overflow-y: auto;
        scrollbar-width: none;
    }
}
</style>
