<template>
    <div v-if="authStore.isUserLoggedIn"
         class="menu-second-layer text-white px-4 py-1 d-flex align-items-center justify-content-between">
        <MobileStreak />
        <RouterLink :to="{ name: dashboardRoutesNames.WALLET_ROUTE_NAME }"
                    @click="scrollToStats">
            <div class="bits-stats">
                <img :src="asset_cdn('assets/missions/coin.svg')"
                     :alt="getTrans('quests-dashboard.bits', 'Bits') + ': ' + bigNumberFormat(Number(userStore.bits) ?? 0)"
                     :title="getTrans('quests-dashboard.bits', 'Bits') + ': ' + bigNumberFormat(Number(userStore.bits) ?? 0)"
                     width="35"
                     height="32">
                <div class="bits-stats__content">
                    <div class="bits-stats__title">
                        {{ getTrans('quests-dashboard.bits', 'Bits') }}
                    </div>
                    <div class="bits-stats__value">
                        <div v-if="userStore.isLoading"
                             class="bits-stats__loader">
                            <Loader :size="16"
                                    :variant="LoaderVariantsEnum.WHITE" />
                        </div>
                        <span v-else>
                            {{ bigNumberFormat(Number(userStore.bits) ?? 0) }}
                        </span>
                    </div>
                </div>
            </div>
        </RouterLink>
        <MobileProfile />
    </div>
</template>

<script setup lang="ts">
    import { getTrans } from '@/js/helpers/getTrans';
    import { asset_cdn, bigNumberFormat } from '@/js/helpers/helpers';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import MobileStreak from './MobileStreak.vue';
    import MobileProfile from './MobileProfile.vue';
    import { scrollToSection } from '@/js/helpers/scrollHandler';
    import { HtmlIds } from '../../constants/htmlIds';
    import { LoaderVariantsEnum } from '@/js/enums/loaderVariants';
    import Loader from '../../../../components/Loader.vue';
    import { dashboardRoutesNames } from '../../constants/dashboardRoutesNames';

    const authStore = useAuthStore();
    const userStore = useUserStore();

    const scrollToStats = () => {
        scrollToSection('profile-stats', { scrollOffsetElementId: HtmlIds.MOBILE_HEADER });
    };
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/variables";

.menu-second-layer {
    background-color: $blackcurrant;
}

.bits-stats {
    display: flex;
    align-items: center;
    color: $baseWhite;
    gap: 8px;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        font-size: 13px;
        text-align: center;
    }

    &__loader {
        display: flex;
        height: 24px;
        align-items: center;
        justify-content: center;
    }

    &__value {
        font-size: 21px;
        font-weight: 700;
        line-height: 24px;
    }
}
</style>
