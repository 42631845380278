<template>
    <transition name="dialog"
                appear>
        <div class="modal d-flex justify-content-center align-items-center modal-transition"
             @click="closeModal()">
            <div class="modal-wrapper">
                <div class="modal-content overflow-hidden text-center p-5 justify-content-between justify-content-md-start"
                     @click.stop>
                    <div v-if="screenIndex === 1"
                         class="d-flex justify-content-between align-items-center">
                        <i class="fa-solid fa-chevron-left close-button"
                           @click="getBackToEmailScreen" />
                        <span class="close-button"
                              @click="closeModal()">&times;</span>
                    </div>
                    <div v-else
                         class="text-left">
                        <span class="close-button"
                              @click="closeModal()">&times;</span>
                    </div>
                    <div v-if="modalStore.modals.showEmailVerify && screenIndex === 0"
                         class="d-flex flex-column justify-content-center h-100">
                        <div>
                            <div class="modal-title">
                                <translate v-if="!authStore.user.email"
                                           translation-key="quests-dashboard.addEmailAddress"
                                           fallback="Add email address" />
                                <translate v-else-if="authStore.user.is_email_verified"
                                           translation-key="quests-dashboard.changeAndVerifyYourEmail"
                                           fallback="Change & verify your email" />
                                <div v-else>
                                    <Translate translationKey="quests-dashboard.verifyYourEmail"
                                               fallback="Verify your email" />!
                                </div>
                            </div>
                            <div class="email-address-input">
                                <label class="mb-1"><Translate translationKey="quests-dashboard.emailAddress"
                                                               fallback="Email address" /></label>
                                <input v-model="emailValue"
                                       class="input"
                                       :class="backendError ? 'error-wrapper--active' : ''"
                                       type="email"
                                       :aria-label="getTrans('quests-dashboard.enterEmailHere', 'Enter email here')"
                                       :placeholder="placeholder"
                                       @keyup.enter="goToVerificationCodeScreen()"
                                       @focus="removePlaceholder"
                                       @blur="restorePlaceholder">
                                <div class="error-message">
                                    <span v-if="backendError">
                                        {{ backendError }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="modalStore.modals.showEmailVerify && screenIndex === 1"
                         class="slide-in d-flex flex-column justify-content-center h-100">
                        <div>
                            <div class="modal-title">
                                <Translate translationKey="quests-dashboard.typeVerification"
                                           fallback="Type in verification code" />
                            </div>
                            <div class="d-flex justify-content-center">
                                <div class="verification-code-info">
                                    <Translate translationKey="quests-dashboard.typeVerificationSubtitle"
                                               fallback="Verification code has been sent to your email address. Enter it below." />
                                </div>
                            </div>
                            <div class="password-input">
                                <label>
                                    <Translate translationKey="quests-dashboard.verificationCode"
                                               fallback="Verification Code" />
                                </label>
                                <div class="verification-code">
                                    <input v-model="verificationCode[0]"
                                           class="input verification-code-input"
                                           :class="backendError ? 'error-wrapper--active' : ''"
                                           maxlength="1"
                                           @paste="handlePaste"
                                           @input="filterInput($event, 0); moveFocus(0)">
                                    <input v-model="verificationCode[1]"
                                           class="input verification-code-input"
                                           :class="backendError ? 'error-wrapper--active' : ''"
                                           maxlength="1"
                                           @paste="handlePaste"
                                           @input="filterInput($event, 1); moveFocus(1)">
                                    <input v-model="verificationCode[2]"
                                           class="input verification-code-input"
                                           :class="backendError ? 'error-wrapper--active' : ''"
                                           maxlength="1"
                                           @paste="handlePaste"
                                           @input="filterInput($event, 2); moveFocus(2)">
                                    <input v-model="verificationCode[3]"
                                           class="input verification-code-input"
                                           :class="backendError ? 'error-wrapper--active' : ''"
                                           maxlength="1"
                                           @paste="handlePaste"
                                           @input="filterInput($event, 3); moveFocus(3)">
                                    <input v-model="verificationCode[4]"
                                           class="input verification-code-input"
                                           :class="backendError ? 'error-wrapper--active' : ''"
                                           maxlength="1"
                                           @paste="handlePaste"
                                           @input="filterInput($event, 4); moveFocus(4)">
                                    <input v-model="verificationCode[5]"
                                           class="input verification-code-input"
                                           :class="backendError ? 'error-wrapper--active' : ''"
                                           maxlength="1"
                                           @paste="handlePaste"
                                           @input="filterInput($event, 5); moveFocus(5)">
                                </div>
                                <div class="error-message">
                                    <span v-if="backendError">
                                        {{ backendError }}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center align-items-center">
                                <div v-if="timer > 0"
                                     class="resend-verification cursor-regular">
                                    <Translate translationKey="quests-dashboard.resendIn"
                                               :replacements="{seconds: timer}" />
                                </div>
                                <div v-else
                                     class="resend-verification link-hover-effect"
                                     @click="resendVerificationCode()">
                                    <Translate translationKey="quests-dashboard.resendVerification"
                                               fallback="Resend verification code" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="modalStore.modals.showEmailVerify && screenIndex === 0"
                         class="email-verify-button-wrapper">
                        <button v-if="timer > 0 && !showButtonLoader"
                                class="px-4 text-capitalize quiz-button--disabled"
                                disabled
                                @click="goToVerificationCodeScreen()">
                            <span>
                                <Translate translationKey="quests-dashboard.verifyIn"
                                           :replacements="{seconds: timer}" />
                            </span>
                        </button>
                        <button v-else
                                :class="(emailValid && (!authStore.user.is_email_verified || !areEmailsEqualIgnoreCase)) ? 'quiz-button--intro' : 'quiz-button--disabled'"
                                class="px-4"
                                :disabled="(authStore.user.is_email_verified && areEmailsEqualIgnoreCase) || !emailValid"
                                @click="goToVerificationCodeScreen()">
                            <span v-if="showButtonLoader"
                                  class="d-flex justify-content-center align-items-center">
                                <Translate translationKey="quests-dashboard.sending"
                                           fallback="Sending" />
                                <Loader class="ml-2"
                                        :variant="LoaderVariantsEnum.BLACK" />
                            </span>
                            <span v-else>
                                <translate v-if="!authStore.user?.email"
                                           translation-key="quests-dashboard.submit"
                                           fallback="Submit" />
                                <translate v-else
                                           translation-key="quests-dashboard.verify"
                                           fallback="Verify" />
                            </span>
                        </button>
                    </div>
                    <button v-else-if="modalStore.modals.showEmailVerify && screenIndex === 1"
                            :class="allInputsFilled ? 'quiz-button--intro' : 'quiz-button--disabled'"
                            class="px-4 mt-40"
                            :disabled="!allInputsFilled"
                            @click="submitVerificationCode()">
                        <Loader v-if="showButtonLoader"
                                :variant="LoaderVariantsEnum.BLACK" />
                        <span v-else>
                            <Translate translationKey="quests-dashboard.submit"
                                       fallback="Submit" />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<!-- this component should be splitted into ±5 components: SettingsEmailModalWrapper, AddEmailContent, VerifyEmailContent, ChangeEmailContent and VerificationCodeContent -->
<!-- not a priority fix this so leaving just a note here -->
<script setup lang="ts">
    import {
        onBeforeUnmount, onMounted, ref, watch, computed, watchEffect,
    } from 'vue';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { validateEmail } from '@/js/helpers/emailValidations';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { getTrans } from '@/js/helpers/getTrans';
    import { EventTypeEnum, KeyEnum } from '@/js/enums/enums';
    import Loader from '../../../../components/Loader.vue';
    import { LoaderVariantsEnum } from '@/js/enums/loaderVariants';

    const userStore = useUserStore();
    const authStore = useAuthStore();
    const modalStore = useModalStore();

    const emailValue = ref(authStore.user?.email || '');

    const verificationCode = ref(['', '', '', '', '', '']);
    const screenIndex = ref(0);
    const backendError = ref<string | null>(null);
    const showButtonLoader = ref(false);
    const defaultPlaceholder = getTrans('quests-dashboard.enterEmailHere');
    const placeholder = ref(defaultPlaceholder);
    const areEmailsEqualIgnoreCase = ref(true);

    const removePlaceholder = () => {
        placeholder.value = '';
    };

    const restorePlaceholder = (event: FocusEvent) => {
        const target = event.target as HTMLInputElement;
        if (!target.value) {
            placeholder.value = defaultPlaceholder;
        }
    };

    const allInputsFilled = computed(() => verificationCode.value.every((code) => code !== ''));
    const emailValid = ref(false);
    const timer = ref(0);
    const intervalId = ref(null);

    const resetData = () => {
        emailValue.value = authStore.user.email ? authStore.user.email : '';
        verificationCode.value = ['', '', '', '', '', ''];
        screenIndex.value = 0;
        backendError.value = null;
        showButtonLoader.value = false;
        timer.value = 0;
        intervalId.value = null;
    };

    const closeModal = () => {
        resetData();
        modalStore.setEmailVerifyModal(false);
    };

    const submitVerificationCode = async () => {
        showButtonLoader.value = true;
        let fullVerificationCode = '';
        verificationCode.value.forEach((val) => {
            fullVerificationCode += val.toUpperCase();
        });
        try {
            await userStore.verifyEmailVerificationCode(fullVerificationCode);
            authStore.setEmail(emailValue.value);
            authStore.user.is_email_verified = true;
            authStore.user.must_verify_email = false;
            backendError.value = null;
            closeModal();

            if (!authStore.user.is_email_connect_enabled) {
                modalStore.setAddPasswordModal(true);
            }
        } catch (error) {
            if (error.response?.data && error.response?.data.message) {
                backendError.value = error.response.data.message;
            }
        }
        showButtonLoader.value = false;
    };
    const startTimer = () => {
        if (intervalId.value) {
            clearTimeout(intervalId.value);
        }
        intervalId.value = setTimeout(() => {
            if (timer.value > 0) {
                timer.value -= 1;
                startTimer();
            } else {
                clearTimeout(intervalId.value);
            }
        }, 1000);
    };

    const goToVerificationCodeScreen = async () => {
        showButtonLoader.value = true;

        if (emailValid.value) {
            try {
                await userStore.sendEmailVerification(emailValue.value);
                backendError.value = null;
                screenIndex.value = 1;
                timer.value = 60;
                startTimer();

                setTimeout(() => {
                    const inputElement = document.querySelector('.verification-code-input') as HTMLInputElement | null;
                    if (inputElement) {
                        inputElement.focus();
                    }
                }, 500);
            } catch (error) {
                if (error.response?.data && error.response?.data.message) {
                    backendError.value = error.response.data.message;
                }
            }
        }

        showButtonLoader.value = false;
    };

    const resendVerificationCode = () => {
        userStore.sendEmailVerification(emailValue.value);
        screenIndex.value = 1;
        timer.value = 60;
        startTimer();
        const inputElement = document.querySelector('.verification-code-input') as HTMLInputElement | null;

        if (inputElement) {
            inputElement.focus();
        }
    };

    const getBackToEmailScreen = () => {
        resetData();
        screenIndex.value = 0;
    };

    watch(emailValue, (newValue) => {
        emailValid.value = validateEmail(newValue);
        backendError.value = null;

        if (authStore.user.email) {
            areEmailsEqualIgnoreCase.value = newValue.toLowerCase() === authStore.user.email.toLowerCase();
        }
    });

    const moveFocus = (index: number) => {
        if (index < 5 && verificationCode.value[index] !== '') {
            const nextInput = document.querySelector(`.verification-code-input:nth-child(${index + 2})`) as HTMLInputElement | null;
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const filterInput = (e: Event, index: number) => {
        const input = e.target as HTMLInputElement;
        if (!input.value.match(/^[a-z0-9]+$/i)) {
            input.value = '';
            verificationCode.value[index] = '';
        }
    };

    const handlePaste = (event: ClipboardEvent) => {
        event.preventDefault();
        const pastedData = event.clipboardData?.getData('text');
        if (!pastedData || pastedData.length !== 6) {
            return;
        }
        const pastedChars = Array.from(pastedData);
        for (let i = 0; i < verificationCode.value.length; i++) {
            verificationCode.value.splice(i, 1, pastedChars[i]);
        }
    };

    const onEscapePressed = (e: { key: string; which: number; }) => {
        if (e.key === KeyEnum.ESCAPE || e.key === KeyEnum.ESC || e.which === 27) {
            closeModal();
        }
    };

    watchEffect(() => {
        if (authStore.user) {
            emailValue.value = authStore.user.email;
        }
    });

    onMounted(() => {
        document.addEventListener(EventTypeEnum.KEYDOWN, onEscapePressed);
        emailValid.value = validateEmail(emailValue.value);
    });

    onBeforeUnmount(() => {
        if (intervalId.value) {
            clearTimeout(intervalId.value);
        }
        document.removeEventListener(EventTypeEnum.KEYDOWN, onEscapePressed);
    });
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/partials/breakpoints";
@import "@/sass/design-system/variables";
@import "@/js/quests/global/sass/mixins/link-hover-effect";

.modal-transition {
    i.check {
        margin-right: 4px;
        color: $primary500;
        font-size: 16px;
    }

    .modal-wrapper {
        max-width: 500px;
        max-height: 500px;
    }

    .modal-content {
        .modal-content-animated {
            overflow: hidden;
            max-height: 0;
            transition: max-height 1.4s ease-in-out;
        }

        .modal-content-animated.is-animated {
            max-height: 500px;
        }

        @media (max-width: $md-breakpoint-max-width) {
            padding: 8px 16px 16px 16px !important;
        }
    }

    div {
        color: $baseWhite;
    }

    .modal-title {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        text-align: center;

        @media (max-width: $md-breakpoint-max-width) {
            font-size: 26px;
            line-height: 32px;
        }
    }

    .modal-subtitle {
        padding: 0 8px;
        font-size: clamp(13px, 2vw, 14px);
    }

    label {
        margin-bottom: 16px;
        color: $off-white-yellow;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
    }

    .quiz-button--disabled,
    .quiz-button--intro {
        &.mt-40 {
            margin-top: 40px;
        }
    }

    .input {
        width: 100%;
        height: 44px;
        padding: 6px 12px;
        border: 1px solid #6e5ba0;
        border-radius: 10px;
        background: transparent;
        color: rgba(255, 255, 255, 0.8);
        font-size: 16px;
        outline: none;
        transition: 0.2s ease-in-out;

        &::placeholder {
            color: #6e5ba0 !important;
        }

        &:hover,
        &:focus {
            border: 1px solid #a897d4;
        }
    }

    .error-wrapper--active {
        border: 1px solid #ff0060 !important;
    }

    .verification-code-info {
        margin-top: 16px;
        color: $whisper-light;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
    }

    .verification-code {
        display: flex;
        justify-content: center;

        .input {
            width: 50px;
            height: 50px;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;

            @media (max-width: $md-breakpoint-max-width) {
                width: 45px;
                height: 45px;
            }

            &:not(:last-child) {
                margin-right: 8px;
            }
        }
    }

    .resend-verification {
        &.link-hover-effect {
            @include link-hover-effect($gray600);
        }

        color: $gray600;
        cursor: pointer;
        font-size: 13px;

        &.cursor-regular {
            cursor: auto;
        }
    }

    .error-message {
        height: 20px;
        margin-top: 3px;
        color: #ff0060;
        font-size: 12px;
        line-height: 20px;
    }

    .check-icon {
        color: #34d35e;
        font-size: 80px;
    }

    .close-button.fa-chevron-left {
        top: 9px;
        right: unset;
        left: 10px;
        font-size: 17px !important;
        font-weight: 600 !important;
    }

    .email-verify-button-wrapper {
        display: flex;
        min-width: 155px;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    .email-address-input {
        margin-top: 40px;
        text-align: left;
    }

    .password-input {
        margin-top: 40px;
        text-align: center;
    }

    @media (max-width: 768px) {
        .close-button.fa-chevron-left {
            padding-top: 5px;
            font-size: 26px !important;
        }

        .email-already-verified {
            font-size: 16px;
        }
    }
}
</style>
