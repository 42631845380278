<template>
    <div class="notifications-footer">
        <div v-if="showLoadMoreButton"
             class="d-flex justify-content-center align-items-center py-2">
            <div class="load-more-notifications my-1"
                 @click="props.loadMoreNotifications">
                <Translate translationKey="quests-dashboard.loadMore"
                           fallback="Load More" />
            </div>
        </div>
        <div v-if="showSpinner"
             class="d-flex justify-content-center align-items-center py-2">
            <Loader :variant="LoaderVariantsEnum.LIGHT_PURPLE" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { useNotificationStore } from '@/js/store/quests/dashboard/NotificationStore';
    import Loader from '../../../../../components/Loader.vue';
    import { LoaderVariantsEnum } from '@/js/enums/loaderVariants';

    const authStore = useAuthStore();
    const notificationStore = useNotificationStore();

    interface NotificationsMobileModalProps {
        loadMoreNotifications: () => void;
    }

    const props = defineProps<NotificationsMobileModalProps>();
    const showLoadMoreButton = computed(() => authStore.isUserLoggedIn && notificationStore.showLoadMore && !notificationStore.isLoading && !notificationStore.isLoadingMore);
    const showSpinner = computed(() => (!notificationStore.notificationData?.data && notificationStore.isLoading) || notificationStore.isLoadingMore);
 </script>

<style scoped lang="scss">
@import "@/sass/partials/variables";

.notifications-footer {
    .load-more-notifications {
        position: relative;
        color: $link-yellow;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;

        &::after {
            position: absolute;
            bottom: -3px;
            left: 50%;
            display: block;
            width: 0;
            height: 1px;
            background: $link-yellow;
            content: "";
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
        }

        &:hover::after {
            left: 0;
            width: 100%;
        }
    }
}
</style>
