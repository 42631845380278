<template>
    <Tippy placement="bottom"
           theme="tip-info"
           trigger="mouseenter"
           animation="fade"
           :class="['streak-item', {
               'success-item': streak.success,
               'frozen-item': streak.frozen,
               'main-reward-item': streak.main_reward }]"
           @click="handleStreakItemClick(streak)">
        <template #default="attrs">
            <div v-bind="attrs">
                <div>
                    <span v-if="streak.frozen"
                          class="streak-day">Break day</span>
                    <span v-else
                          class="streak-day">Day {{ streak.streak_count }}</span>
                    <div v-if="isLastStreak"
                         class="images-section">
                        <ChestBox />
                    </div>
                    <div v-else-if="streak.frozen">
                        <img width="29"
                             :src="asset_cdn('assets/missions/in-game-images/end-game-screens/timeout/timeout2.svg', 29)"
                             alt="Frozen Icon"
                             class="frozen-icon">
                    </div>
                    <div v-else>
                        <img width="28"
                             :src="asset_cdn('assets/missions/coin.svg', 28)"
                             alt="BITS Icon"
                             class="bits-icon">
                    </div>
                    <div v-if="!streak.frozen"
                         class="d-flex align-items-center justify-content-center">
                        <span v-if="!streak.main_reward"
                              class="streak-reward">{{ formattedDailyReward }}</span>
                        <span v-if="streak.main_reward"
                              class="streak-reward">Special Bonus</span>
                    </div>
                    <div v-else
                         class="break-text">
                        0
                    </div>
                </div>
                <div v-if="streak.success"
                     class="success-icon-container">
                    <i class="badge__icon fa-solid fa-badge-check" />
                </div>
                <div v-if="streak.main_reward">
                    <img :src="asset_cdn('assets/missions/in-game-images/motivation-char-assets/greatrow2.svg', 40)"
                         alt="Special Bonus"
                         class="special-bonus-img"
                         width="40">
                </div>
            </div>
        </template>
        <template #content>
            <div class="info">
                <p class="mb-0">
                    {{ tippyText }}
                </p>
            </div>
        </template>
    </Tippy>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { Tippy } from 'vue-tippy';
    import ChestBox from '@/js/quests/dashboard/svg/ChestBox.vue';
    import { asset_cdn, formatNumberToUSLocaleString } from '@/js/helpers/helpers';
    import { StreakData } from '@/js/quests/dashboard/types/streak';
    import { useTooltipStore } from '@/js/store/quests/game/modules/TippyMobileStore';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';
    import { generateTippyText } from '@/js/quests/dashboard/navigation/streak/helpers/streakTippyText';

    const props = defineProps({
        streak: {
            type: Object as () => StreakData,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        streakDataLength: {
            type: Number,
            required: true,
        },
    });

    const tooltipStore = useTooltipStore();

    const { isMobileOrTablet } = useDeviceSize();

    const isLastStreak = computed(() => props.index === props.streakDataLength - 1);

    const tippyText = computed(() => generateTippyText(props.streak));

    const emit = defineEmits<{(e: 'streakItemClick', streakData: StreakData): void; }>();

    const formattedDailyReward = computed(() => formatNumberToUSLocaleString(props.streak?.daily_reward));

    const handleStreakItemClick = (streakData: StreakData) => {
        if (isMobileOrTablet.value) {
            emit('streakItemClick', streakData);
            tooltipStore.setStreakItemTooltip(true);
        }
    };
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";

.streak-item {
    position: relative;
    display: flex;
    height: 80px;
    flex: 1 0 calc(25% - 12px);
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 2px solid $gray200;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: default;
    text-align: center;
    transition: border 0.3s ease;

    .success-icon-container {
        position: absolute;
        top: -4px;
        right: -5px;

        @media (max-width: $md-breakpoint) {
            top: -7px;
            right: -7px;
        }

        .badge__icon {
            margin: 0;
            font-size: 20px;

            @media (max-width: $md-breakpoint) {
                font-size: 28px;
            }
        }
    }

    @media (max-width: $md-breakpoint) {
        height: 120px;
    }

    &:hover {
        background-color: rgba($gray100, 0.75);
        opacity: 0.9;
    }

    &.success-item,
    &.frozen-item {
        border-color: $warning400;
        background-color: $warning50;

        &:hover {
            opacity: 0.8;
        }
    }

    &.main-reward-item {
        position: relative;
        display: flex;
        align-items: flex-start;
        padding-left: 10px;
        border-width: 2px;

        @media (max-width: $md-breakpoint) {
            padding-left: 40px;
        }

        @media (max-width: $sm-breakpoint) {
            padding-left: 10px;
        }

        @media (max-width: 374px) {
            align-items: center;
            padding-left: 0;
        }

        .special-bonus-img {
            position: absolute;
            right: 8px;
            bottom: 16px;
            width: 40px;

            @media (max-width: $md-breakpoint) {
                top: 6px;
                right: 16px;
                width: 80px;
            }

            @media (max-width: $sm-breakpoint) {
                top: 16px;
                right: 16px;
                width: 65px;
            }

            @media (max-width: 425px) {
                top: 16px;
                right: 6px;
                width: 60px;
            }

            @media (max-width: 374px) {
                display: none;
            }
        }
    }

    .break-text,
    .streak-day,
    .streak-reward {
        color: $baseBlack;
        font-size: 12px;
        font-weight: 700;

        @media (max-width: $md-breakpoint) {
            font-size: 16px;
        }

        @media (max-width: $mobile-lg-breakpoint) {
            font-size: 14px;
        }

        @media (max-width: $mobile-md-breakpoint) {
            font-size: 12px;
        }
    }

    .images-section {
        color: $baseBlack;
    }

    .bits-icon {
        width: 28px;
        margin: 4px 0;

        @media (max-width: $md-breakpoint) {
            width: 40px;
            margin: 8px 0;
        }
    }

    .frozen-icon {
        width: 29px;
        margin: 2px 0;

        @media (max-width: $md-breakpoint) {
            width: 42px;
            margin: 6px 0;
        }
    }

    .images-section {
        width: 26px;
        margin: 4px auto;

        @media (max-width: $md-breakpoint) {
            width: 40px;
        }
    }

    i {
        margin: 6px 0;
        font-size: 24px;

        @media (max-width: $md-breakpoint) {
            margin: 8px 0;
            font-size: 36px;
        }

        &.fa-badge-check {
            color: $primary500;
        }
    }
}

.streak-item:last-child {
    flex: 0 0 calc(50% - 4px);
}
</style>
