<template>
    <div :class="{'tippy-disabled': userStore.isLoading}"
         class="streak-tippy">
        <div ref="desktopStreakTippy"
             class="streak-container"
             :class="[streakContainerClass]">
            <div class="streak-fire d-flex align-items-center justify-content-center">
                <StreakFire :title="authStore.isUserLoggedIn
                    ? getTrans('quests-dashboard.streakCount', 'Streak Count') + ': ' + userStore.streakCount
                    : getTrans('quests-dashboard.connectToStreak', 'Connect to Streak')" />
            </div>
            <div v-if="authStore.isUserLoggedIn"
                 class="streak-number">
                <div v-if="userStore.isLoading"
                     class="d-flex align-items-center h-100">
                    <Loader :size="14"
                            :variant="LoaderVariantsEnum.BLACK" />
                </div>
                <template v-else>
                    {{ userStore.streakCount || 0 }}
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref, computed } from 'vue';
    import { getTrans } from '@/js/helpers/getTrans';
    import StreakFire from '../../svg/StreakFire.vue';
    import StreakCard from '../streak/StreakCard.vue';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { useNavigationStore } from '@/js/store/quests/dashboard/NavigationStore';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import useTippyPopup from '../../../../composables/useTippyPopup';
    import { LoaderVariantsEnum } from '@/js/enums/loaderVariants';
    import Loader from '../../../../components/Loader.vue';

    const authStore = useAuthStore();
    const userStore = useUserStore();
    const navigationStore = useNavigationStore();

    const desktopStreakTippy = ref(null);

    const { show } = useTippyPopup(desktopStreakTippy, {
        content: StreakCard,
        arrow: true,
        interactive: true,
        theme: 'tip-info',
        placement: 'bottom',
        trigger: 'click',
    });

    const streakContainerClass = computed(() => {
        let classList = !authStore.isUserLoggedIn ? 'pr-12px' : '';

        if (userStore.isLoading) {
            classList += ' streak-container-bg-loading';
        }

        if (userStore.streakCount > 0 || userStore.streakCount === null) {
            classList += ' streak-container-bg-success';
        } else {
            classList += ' streak-container-bg-danger';
        }

        return classList;
    });

    onMounted(() => {
        navigationStore.showStreakTippyFunction = show;
    });
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/design-system/variables";

.streak-tippy {
    display: flex;
    align-items: center;
    margin-left: 16px;

    .streak-container-bg-success {
        background-color: $primary500;
    }

    .streak-container-bg-danger {
        background-color: $down-color;
    }

    .streak-container-bg-loading {
        background-color: $light-grey-blue;
    }

    .streak-container {
        display: flex;
        min-width: 40px;
        min-height: 27px;
        align-items: center;
        justify-content: center;
        padding: 4px 8px 4px 12px;
        border-radius: 20px;
        cursor: pointer;
        font-size: 19px;
        gap: 4px;

        &.pr-12px {
            padding-right: 12px;
        }

        .streak-fire {
            width: 15px;
            height: 15px;
        }

        .streak-number {
            display: flex;
            min-width: 14px;
            height: 24px;
            justify-content: center;
            font-size: 16px;
            font-weight: 700;
        }
    }
}
</style>
