<template>
    <div />
</template>

<script setup lang="ts">
    import { computed, watch } from 'vue';
    import { useBrowserNotifications } from '@/js/quests/dashboard/composables/useBrowserNotifications';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';

    const authStore = useAuthStore();
    const userStore = useUserStore();
    const modalStore = useModalStore();

    const { isMobileOrTablet } = useDeviceSize();
    const {
        isSubscribed,
        token,
        notification,
        isSubscribeAvailable,
        isFirebaseSupported,
        isInitialiseCompleted,
        initialiseNotification,
        checkFirebaseTokenRequested,
    } = useBrowserNotifications();

    const isLoggedInAndUserFetched = computed(() => authStore.isUserFetchCompleted && authStore.isUserLoggedIn && userStore.isQuestsUserFetchCompleted);
    const isInitialiseCompletedAndUserFetched = computed(() => isInitialiseCompleted.value && isLoggedInAndUserFetched.value);
    const isEligibleForNotifications = computed(() => !userStore.isNewUserSignup && !isMobileOrTablet.value && isSubscribeAvailable.value);

    watch(() => notification.value, (newValue) => {
        if (newValue) {
            // TODO: implement notifications.
        }
    });

    watch(() => isInitialiseCompletedAndUserFetched.value, async (newValue) => {
        if (newValue && isFirebaseSupported.value && isEligibleForNotifications.value) {
            modalStore.setBrowserNotificationsModal(true);
        }

        if (isSubscribed.value) {
            await checkFirebaseTokenRequested(token.value);
        }
    });

    watch(() => isFirebaseSupported.value, async (newValue) => {
        if (newValue) {
            await initialiseNotification();
        }
    }, { immediate: true });
</script>
