<template>
    <div class="d-flex">
        <div class="dashboard-sidebar"
             :class="{
                 'hidden': windowWidth <= BreakpointsEnum.TABLET_WIDTH_IN_PX ? !navigationStore.showSidebar : false,
                 'dashboard-sidebar--moved-down': isMovedDown,
             }">
            <div class="guilds-wrapper">
                <template v-if="!isFollowedGuildsFetched">
                    <div v-for="index in DEFAULT_SIDEBAR_GUILDS_COUNT"
                         :key="index"
                         class="d-flex justify-content-center align-items-center mt-2 mb-1">
                        <BaseSkeletonLoader width="45px"
                                            height="45px" />
                    </div>
                </template>
                <template v-else>
                    <div v-for="(guild, index) in guilds"
                         :key="index">
                        <SidebarGuild :guild="guild"
                                      :isActive="currentGuildSlug === guild.slug"
                                      @guildClick="handleGuildClick" />
                    </div>
                </template>

                <hr class="col-1 mb-2 mt-3">

                <Tippy placement="right"
                       theme="tip-info"
                       trigger="mouseenter"
                       animation="fade">
                    <template #default>
                        <RouterLink :to="{ name: dashboardRoutesNames.GUILDS_ROUTE_NAME }"
                                    aria-label="Search Guilds">
                            <div class="add-guild"
                                 @click="navigationStore.showSidebar = false">
                                <i class="fa-solid fa-plus" />
                            </div>
                        </RouterLink>
                    </template>
                    <template #content>
                        <div class="info">
                            <p class="mb-0">
                                <Translate translationKey="quests-dashboard.guilds.followMoreGuilds"
                                           fallback="Follow more Guilds" />
                            </p>
                        </div>
                    </template>
                </Tippy>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, watch } from 'vue';
    import { Tippy } from 'vue-tippy';
    import { storeToRefs } from 'pinia';
    import { useNavigationStore } from '@/js/store/quests/dashboard/NavigationStore';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';
    import { useEventListener } from '@/js/composables/useEventListener';
    import { dashboardRoutesNames } from '../constants/dashboardRoutesNames';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { BreakpointsEnum } from '@/js/enums/breakpoints';
    import BaseSkeletonLoader from '../../../components/BaseSkeletonLoader.vue';
    import { DEFAULT_SIDEBAR_GUILDS_COUNT } from '../constants';
    import router from '../../../routes/dashboard.routes';
    import SidebarGuild from './sidebar/SidebarGuild.vue';

    defineProps<{ isMovedDown: boolean}>();

    const navigationStore = useNavigationStore();
    const userStore = useUserStore();
    const authStore = useAuthStore();

    const { followedGuilds: guilds, isFollowedGuildsFetched } = storeToRefs(userStore);
    const { fetchSidebarFollowedGuilds, setFollowedGuildAsSeen } = userStore;
    const { windowWidth } = useDeviceSize();

    const currentGuildSlug = computed(() => router.currentRoute.value.params.guildSlug as string);

    const handleGuildClick = (slug: string) => {
        navigationStore.showSidebar = false;
        setFollowedGuildAsSeen(slug);
    };

    const closeSidebarOnScroll = () => {
        if (windowWidth.value <= BreakpointsEnum.TABLET_WIDTH_IN_PX) {
            navigationStore.showSidebar = false;
        }
    };

    useEventListener(window, 'scroll', closeSidebarOnScroll);

    watch(() => authStore.isUserLoggedIn, async () => {
        await fetchSidebarFollowedGuilds();
    }, { immediate: true });
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/design-system/variables";
@import "@/sass/missions-partials/z-index";

.dashboard-sidebar {
    position: fixed;
    z-index: $sidebar-z-index;
    top: 0;
    left: 0;
    width: $dashboard-sidebar-width;
    height: 100vh;
    padding: 4px;
    background-color: #1c002f;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    transform: translateX(0);
    transition: transform 0.5s ease-out;

    &.hidden {
        transform: translateX(-100%);
        transition: transform 0.5s ease-out;
    }

    .guilds-wrapper {
        width: 100%;
        padding-right: 4px;
        padding-left: 4px;
        text-align: center;

        span {
            font-size: 10px;
        }

        hr {
            border: 1px solid #300f4a;
        }

        .add-guild {
            display: inline-flex;
            width: 45px;
            height: 45px;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            background-color: #311049;
            color: #274d55;
            cursor: pointer;
            font-size: 22px;

            i {
                transform-origin: center;
                transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
            }

            &:hover {
                i {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    @media (max-width: 1024px) {
        border-right: 1px solid $dark-theme-border;
        background-color: $blackcurrant-dark;
        border-bottom-right-radius: 15px;
        border-top-right-radius: 15px;
    }

    &--moved-down {
        top: $dashboard-bar-height;
    }
}
</style>
