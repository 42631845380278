<template>
    <nav class="navigation-mobile">
        <MenuCardItem translationKey="quests-dashboard.allMissions"
                      fallback="All Missions"
                      :routeLink="dashboardRoutesNames.MISSIONS_ROUTE_NAME" />
        <MenuCardItem translationKey="quests-dashboard.guilds.menuTitle"
                      fallback="Guilds"
                      :routeLink="dashboardRoutesNames.GUILDS_ROUTE_NAME" />
        <MenuCardItem translationKey="quests-dashboard.friends"
                      fallback="Friends"
                      :routeLink="dashboardRoutesNames.FRIENDS_ROUTE_NAME"/>
        <MenuCardItem translationKey="quests-dashboard.airdrops"
                      fallback="Airdrops"
                      :routeLink="dashboardRoutesNames.LEADERBOARD_ROUTE_NAME"
                      isNew/>
        <MenuCardItem translationKey="quests-dashboard.bonus.menuTitle"
                      fallback="Bonus"
                      :routeLink="dashboardRoutesNames.BONUS_ROUTE_NAME"
                      isHot/>
        <MenuCardItem translationKey="quests-dashboard.wallet"
                      fallback="Wallet"
                      :isLinkElement="authStore.isUserLoggedIn"
                      :routeLink="dashboardRoutesNames.WALLET_ROUTE_NAME"
                      @click="handleAuthRequiredTabClick" />
        <MenuCardItem translationKey="quests-dashboard.createMission"
                      fallback="Create Mission"
                      :isLinkElement="authStore.isUserLoggedIn"
                      :routeLink="dashboardRoutesNames.GUILDS_STUDIO_ROUTE_NAME"
                      icon="⚡"
                      @click="handleAuthRequiredTabClick" />
        <MenuCardItem translationKey="quests-dashboard.cryptoHub"
                      fallback="Crypto Hub"
                      :isLinkElement="true"
                      :externalLink="route('cryptocurrencies-index')" />
    </nav>
</template>

<script setup lang="ts">
    import MenuCardItem from './MenuCardItem.vue';
    import { dashboardRoutesNames } from '../../constants/dashboardRoutesNames';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { route } from '@/js/helpers/helpers';

    const authStore = useAuthStore();
    const modalStore = useModalStore();

    const handleAuthRequiredTabClick = () => {
        if (!authStore.isUserLoggedIn) {
            modalStore.setAuthModal(true);
        }
    };
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/missions-partials/z-index";

.navigation-mobile {
    position: absolute;
    z-index: $navigation-mobile-z-index;
    top: 70px;
    right: 30px;
    flex-direction: column;
    padding: 16px;
    border: 1px solid $jacarta;
    border-radius: 12px;
    background-color: $toolbar-purple;
    opacity: 1;
    transition: 0.2s ease-in-out;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    .coming-soon {
        top: -9px;
    }
}
</style>
