<template>
    <div class="navigation-link">
        <div v-if="requiresAuth && !authStore.isUserLoggedIn"
             class="link"
             @click="modalStore.setAuthModal(true)">
            <span v-if="icon">
                {{ icon }}
            </span>
            <span>
                <Translate :translationKey="`quests-dashboard.${translationKey}`"
                           :fallback="fallback" />
            </span>
        </div>
        <template v-else>
            <a v-if="externalLink"
               :href="externalLink"
               target="_blank"
               class="link">
                <span v-if="icon"
                      class="navigation-link__icon">
                    {{ icon }}
                </span>
                <span>
                    <Translate :translationKey="`quests-dashboard.${translationKey}`"
                               :fallback="fallback" />
                </span>
            </a>
            <RouterLink v-else
                        :to="{ name: routeLink }"
                        class="link"
                        activeClass="active">
                <span v-if="icon"
                      class="navigation-link__icon">
                    {{ icon }}
                </span>
                <span>
                    <Translate :translationKey="`quests-dashboard.${translationKey}`"
                               :fallback="fallback" />
                </span>
            </RouterLink>
        </template>
        <div v-if="isNew || isHot"
             class="navigation-link__badge"
             :class="{'navigation-link__badge--new': isNew, 'navigation-link__badge--hot': isHot}">
            <Translate v-if="isNew"
                       translationKey="quests-dashboard.new"
                       fallback="New" />
            <Translate v-else-if="isHot"
                       translationKey="quests-dashboard.hot"
                       fallback="Hot" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { RouteRecordName } from 'vue-router';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import Translate from '@/js/helpers/Translate.vue';

    defineProps<{
        routeLink?: RouteRecordName,
        translationKey: string,
        fallback: string,
        icon?: string,
        requiresAuth?: boolean,
        externalLink?: string,
        isNew?: boolean,
        isHot?: boolean,
    }>();

    const authStore = useAuthStore();
    const modalStore = useModalStore();
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";

.navigation-link {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-left: 16px;
    color: $baseWhite;

    &__badge {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0 3px;
        border-radius: 6px;
        color: $baseWhite;
        font-size: 9px;
        text-transform: uppercase;

        &--new {
            background: $brand400;
        }

        &--hot {
            background: $error600;
        }
    }

    .link {
        font-size: 16px;
    }

    @media (max-width: 1250px) {
        .link {
            font-size: 15px;
        }
    }

    @media (max-width: $xl-breakpoint-max-width) {
        margin-left: 12px;
    }

    @media (max-width: 1160px) {
        .link {
            font-size: 13px;
        }
    }

    @media (max-width: 1080px) {
        .link {
            font-size: 11px;
        }
    }
}
</style>
