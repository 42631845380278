<template>
    <div class="guilds-list">
        <Loader v-if="isFirstPageLoading"
                class="guilds-list__loader"
                :size="60" />
        <template v-else>
            <div v-for="(guildCard) in suggestedGuilds.list"
                 :key="guildCard.slug"
                 class="guilds-list-item">
                <div class="guilds-list-item__guild-wrapper">
                    <img v-if="guildCard.logo.url"
                         :src="asset_cdn(guildCard.logo.url, 45)"
                         :alt="`${guildCard.title} icon`"
                         class="guilds-list-item__icon"
                         width="45"
                         height="45">
                    <div class="guilds-list-item-data">
                        <div class="guilds-list-item-data__title">
                            {{ guildCard.title }}
                            <i v-if="guildCard.verification_status === VerificationStatusEnum.VERIFIED"
                               class="fa-solid fa-badge-check" />
                        </div>
                        <div class="guilds-list-item-data__gainers">
                            <i class="fa-regular fa-gamepad-modern"/>
                            <span>{{ bigNumberFormat(guildCard.player_count) }}</span>
                            <Translate translation-key="quests-dashboard.gainers"
                                       fallback="Gainers" />
                        </div>
                    </div>
                </div>
                <GuildsSuggestionsModalContentFollowButton :guildSlug="guildCard.slug"
                                                           :isFollowed="guildCard.is_followed"
                                                           :isFollowMandatory="guildCard.is_follow_mandatory"
                                                           @click="onFollowToggle"/>
            </div>
            <template v-if="!suggestedGuilds.isLastPage">
                <div v-if="isNextPageLoading"
                     class="guilds-list__inner-loader">
                    <Loader size="40px" />
                </div>
                <div v-else
                     class="guilds-list__load-more">
                    <BaseButton :variant="ButtonVariantEnum.QUATERNARY"
                                @click="handleLoadMore">
                        <Translate translationKey="quests-dashboard.loadMore"
                                   fallback="Load More" />
                    </BaseButton>
                </div>
            </template>
            <div v-if="noResults"
                 class="guilds-list__no-results">
                <p>
                    <Translate translationKey="quests-dashboard.guilds.guildsNotFound"
                               fallback="No Guilds found" />
                </p>
                <img :src="asset_cdn('assets/missions/in-game-images/motivation-exit.svg')"
                     width="120"
                     alt="No Guilds found"
                     title="No Guilds found">
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
    import { storeToRefs } from 'pinia';
    import { computed, onMounted } from 'vue';
    import { useGuildsStore } from '@/js/store/quests/dashboard/GuildsStore';
    import Loader from '@/js/components/Loader.vue';
    import { asset_cdn, bigNumberFormat } from '@/js/helpers/helpers';
    import { VerificationStatusEnum } from '@/js/quests/dashboard/types/enums';
    import GuildsSuggestionsModalContentFollowButton
        from '@/js/quests/dashboard/pages/guilds/components/GuildsSuggestionsModalContentFollowButton.vue';
    import { ButtonVariantEnum } from '@/js/types/components/ButtonVariantEnum';
    import BaseButton from '@/js/components/BaseButton.vue';

    const guildsStore = useGuildsStore();

    const { suggestedGuilds } = storeToRefs(guildsStore);
    const { fetchSuggestedGuilds, toggleSuggestedGuildFollow, increaseSuggestedGuildsCurrentPage } = guildsStore;

    const isFirstPageLoading = computed(() => !suggestedGuilds.value.isFetchCompleted || (suggestedGuilds.value.currentPage === 1 && suggestedGuilds.value.isLoading));
    const isNextPageLoading = computed(() => suggestedGuilds.value.currentPage > 1 && suggestedGuilds.value.isLoading);
    const noResults = computed(() => suggestedGuilds.value.isLastPage && suggestedGuilds.value.list.length === 0);

    const onFollowToggle = (slug: string, status: boolean) => {
        toggleSuggestedGuildFollow(slug, status);
    };

    const handleLoadMore = async () => {
        increaseSuggestedGuildsCurrentPage();

        await fetchSuggestedGuilds();
    };

    onMounted(async () => {
        await fetchSuggestedGuilds();
    });
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";

.guilds-list {
    min-height: 200px;

    &__loader {
        margin: 128px 0;
    }

    &__inner-loader {
        padding: 16px 0 0;
    }

    &__load-more {
        button {
            margin: 16px auto 0;
            font-size: 13px;
        }
    }

    &__no-results {
        color: $baseWhite;
        text-align: center;

        p {
            margin: 16px 0;
        }
    }
}

.guilds-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid $blue900;
    color: $baseWhite;

    &:last-child {
        border-bottom: none;
    }

    &__guild-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    &__icon {
        border: 2px solid $blue800;
        border-radius: 50%;
    }

    &-data {
        display: flex;
        flex-direction: column;

        &__title {
            font-size: 14px;

            i {
                color: $primary500;
            }

            @media (max-width: $sm-breakpoint-max-width) {
                font-size: 12px;
            }
        }
        &__gainers {
            display: flex;
            align-items: center;
            font-size: 12px;
            gap: 4px;

            span {
                font-weight: 700;
            }

            @media (max-width: $sm-breakpoint-max-width) {
                font-size: 10px;
            }
        }

    }
}
</style>
