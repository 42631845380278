import { StreakData } from '@/js/quests/dashboard/types/streak';
import { formatNumberToUSLocaleString } from '@/js/helpers/helpers';

export const generateTippyText = (streakData: StreakData): string => {
    const dailyReward = streakData.daily_reward || 0;
    const mainReward = streakData.main_reward || 0;
    const totalBonus = dailyReward + mainReward;
    const formattedDailyReward = formatNumberToUSLocaleString(dailyReward);

    if (streakData.frozen) {
        return 'Break day. Your streak is safe and still going strong – no resetting.';
    }

    if (streakData.success) {
        return `Streak reached! You've earned ${formattedDailyReward} Bits as a bonus.`;
    }

    if (streakData.main_reward && !streakData.success) {
        return `You're so close! Extend your streak to earn a special bonus of ${formatNumberToUSLocaleString(totalBonus)} Bits!`;
    }

    if (streakData.main_reward && streakData.success) {
        return `Congrats! You've unlocked a special bonus of ${formatNumberToUSLocaleString(totalBonus)} Bits!`;
    }

    return `Keep going! Finish a new round to complete your streak and earn ${formattedDailyReward} Bits.`;
};
