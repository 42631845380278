import { createApp, defineAsyncComponent } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueTippy from 'vue-tippy';
import VueSplide from '@splidejs/vue-splide';
import router from '@/js/routes/dashboard.routes';
import '@/js/bootstrap';
import '@/js/helpers/setDynamicVH';
import { vFocus } from '@/js/directives/vFocus';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

import Translate from '@/js/helpers/Translate.vue';
import DashboardLayout from '@/js/quests/dashboard/DashboardLayout.vue';
import ClosingCurtains from '@/js/quests/game/ClosingCurtains.vue';
import { sentryErrorsIgnoreList } from '@/js/constants';
import { VITE_APP_ENV, VITE_SENTRY_DSN } from '@/js/helpers/environment';

const AppPopup = defineAsyncComponent(() => import('@/js/global/top-menu/AppPopup.vue'));

const app = createApp({});

app.component('Translate', Translate);
app.component('DashboardLayout', DashboardLayout);
app.component('ClosingCurtains', ClosingCurtains);
app.component('AppPopup', AppPopup);

Sentry.init({
    app,
    dsn: VITE_SENTRY_DSN,
    environment: VITE_APP_ENV,
    ignoreErrors: sentryErrorsIgnoreList,
    denyUrls: [/^chrome-extension:\/\//i],
});

app.directive('focus', vFocus);

app.use(createPinia());

app.use(VueSplide);
app.use(VueToast);

app.use(VueTippy, {
    directive: 'tippy',
    component: 'tippy',
    componentSingleton: 'tippy-singleton',
    defaultProps: {
        placement: 'auto-end',
        allowHTML: true,
    },
});

app.use(router);

app.mount('#missions-mount');
