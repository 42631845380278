import { defineStore } from 'pinia';
import { ResponseStreakData } from '@/js/quests/dashboard/types/streak';
import { getStreakData } from '@/js/quests/dashboard/services/api';

interface StreakStoreState {
    streakData: ResponseStreakData | null;
}
export const useStreakStore = defineStore({
    id: 'streak',
    state: (): StreakStoreState => ({
        streakData: null,
    }),
    actions: {
        async fetchStreakData() {
            try {
                this.streakData = await getStreakData();
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching streak data:', error);
            }
        },
    },
});
