<template>
    <NotificationRow :notification="promoNotification"
                     @click="emit('click')" />
</template>

<script setup lang="ts">
    import NotificationRow from '@/js/quests/dashboard/modals/notifications/header-notifications/NotificationRow.vue';
    import { dashboardRoutesPaths } from '@/js/quests/dashboard/constants/dashboardRoutesPaths';

    const emit = defineEmits<{(e: 'click'): void }>();

    const promoNotification = {
        title: '🔥 $30K BitDegree Airdrop is Live',
        subtitle: 'Collect Bits & earn rewards from the $30K Prize Pool!',
        image_path: 'assets/missions/notifications/bits-earned.svg',
        read_at: '2025-02-20T23:59:59.000000Z',
        url: dashboardRoutesPaths.LEADERBOARD_ROUTE_PATH,
    };
</script>
