<template>
    <div class="connect-to-gain-footer">
        <BaseButton @click="modalStore.setAuthModal(true)">
            <Translate translationKey="quests-dashboard.connectToGain"
                       fallback="Connect To Gain" />
        </BaseButton>
    </div>
</template>

<script setup lang="ts">
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import BaseButton from '@/js/components/BaseButton.vue';

    const modalStore = useModalStore();
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/variables";
@import "@/sass/missions-partials/z-index";
@import "@/js/quests/global/sass/mixins/animations";

$mobile-footer-height: 60px;

.connect-to-gain-footer {
    position: fixed;
    z-index: $connect-to-gain-footer-z-index;
    bottom: $mobile-footer-height;
    left: 0;
    width: 100%;
    padding: 16px;
    border-top: 1px solid $dark-theme-border;
    background-color: $brand950;
    box-shadow: 0 -10px 15px 0 rgba($brand950, 0.7);

    button {
        @include glow-animation;
        border: 1px solid $primary700;
        animation-delay: 0s;
    }
}
</style>
