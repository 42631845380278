<template>
    <div class="streak-card text-white">
        <template v-if="authStore.isUserLoggedIn">
            <div class="slider-container">
                <StreakSlider :streakCompletedTotal="userStore.streakCount ?? 0"
                              :streakBreakNumber="userStore.user?.data?.quest_profile?.quest_streak?.freeze_points ?? 0"
                              :closeTippy="closeTippy" />
            </div>
            <div class="streak-content-card py-2 mx-auto">
                <div class="px-3 text-center">
                    <div v-if="streakCompletedCurrent === MAX_STREAK"
                         class="streak-card__bonus-text">
                        You have reached special bonus! 🎉
                    </div>
                    <span v-else
                          class="streak-card__bonus-text">
                        <span class="text-success mr-1">
                            <template v-if="isLoading">
                                <span class="streak-current-loader" />
                            </template>
                            <span v-else
                                  class="mr-1">
                                <Translate translationKey="quests-dashboard.xday"
                                           :fallback="'x' + xday + '-day'"
                                           :replacements="{days: xday }"/>
                            </span>
                            <Translate translationKey="quests-dashboard.streak"
                                       fallback="Streak" />
                        </span>
                        <Translate translationKey="quests-dashboard.aheadUntilNextReward"
                                   fallback="ahead until special bonus!" />
                    </span>
                    <div class="mt-2">
                        <ProgressBar :streakCompleted="streakCompletedCurrent || 0" />
                    </div>
                </div>
                <div class="streak-container">
                    <template v-if="isLoading">
                        <span class="streak-loader mt-3" />
                    </template>
                    <div v-else
                         class="streaks-display d-flex justify-content-between px-3 px-md-0">
                        <StreakItem v-for="(streak, index) in streakData"
                                    :key="index"
                                    class="streak-item"
                                    :streak="streak"
                                    :index="index"
                                    :streakDataLength="streakData.length"
                                    @streakItemClick="updateTippyText" />
                        <TippyMobile name="streakItem">
                            <div class="info">
                                <p class="mb-0">
                                    <i class="fa-regular fa-circle-question mr-1" />
                                    {{ streakItemTippyText }}
                                </p>
                            </div>
                        </TippyMobile>
                    </div>
                </div>
            </div>
        </template>
        <div v-else
             class="unauthenticated-card pb-3 py-md-3 mx-0 mx-md-2">
            <div class="d-flex align-items-center justify-content-center">
                <div class="streak-fire mx-1">
                    <StreakFire :color="Colors.STREAK_FIRE_COLOR" />
                </div>
            </div>
            <div class="text-center">
                <p class="mb-0 streak-text text-white">
                    <Translate translationKey="quests-dashboard.dayStreak"
                               fallback="day streak" />!
                </p>
                <div class="tippy-info d-flex align-items-center justify-content-center">
                    <p class="text-white mb-0">
                        <Translate translationKey="quests-dashboard.earnMoreBy"
                                   fallback="Earn more Bits by keeping it up" />
                    </p>
                    <div ref="informationTippy"
                         class="d-flex">
                        <i class="fa-regular fa-circle-info"
                           :aria-label="getTrans('quests-dashboard.streakTooltip', 'Streak tooltip')"
                           @click="tooltipStore.setStreakTippyEarnMoreTooltip(true)" />
                    </div>
                </div>
                <div class="my-3">
                    <button class="quiz-button--correct"
                            @click="showAuthModal">
                        <Translate translationKey="quests-dashboard.connectToStreak"
                                   fallback="Connect to Streak" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        ref, computed, onMounted,
    } from 'vue';
    import ProgressBar from '@/js/quests/dashboard/navigation/streak/StreakProgressBar.vue';
    import { useStreakStore } from '@/js/store/quests/dashboard/StreakStore';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import StreakSlider from '@/js/quests/dashboard/navigation/streak/StreakSlider.vue';
    import StreakFire from '@/js//quests/dashboard/svg/StreakFire.vue';
    import { ResponseStreakData, StreakData } from '@/js//quests/dashboard/types/streak';
    import { useTooltipStore } from '@/js/store/quests/game/modules/TippyMobileStore';
    import { showToast } from '@/js/helpers/toastHelpers';
    import { getTrans } from '@/js/helpers/getTrans';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import useTippyPopup from '@/js/composables/useTippyPopup';
    import { Colors } from '@/js/quests/dashboard/constants/colors';
    import { MAX_STREAK } from '@/js/quests/dashboard/constants/streak';
    import StreakItem from '@/js/quests/dashboard/navigation/streak/StreakItem.vue';
    import TippyMobile from '@/js/quests/global/widgets/tooltips/TippyMobile.vue';
    import { generateTippyText } from '@/js/quests/dashboard/navigation/streak/helpers/streakTippyText';

    const useStreak = useStreakStore();
    const userStore = useUserStore();
    const authStore = useAuthStore();
    const tooltipStore = useTooltipStore();
    const modalStore = useModalStore();

    const props = defineProps({
        closeTippy: {
            type: Function,
            default: () => {},
        },
    });

    const informationTippy = ref(null);
    const streakCompletedTotal = ref<number | null>(null);
    const streakCompletedCurrent = computed(() => {
        if (streakCompletedTotal.value !== null) {
            if (streakCompletedTotal.value === MAX_STREAK) {
                return MAX_STREAK;
            }

            return streakCompletedTotal.value % MAX_STREAK;
        }

        return null;
    });

    const xday = computed(() => MAX_STREAK - (streakCompletedCurrent.value ?? 0));

    useTippyPopup(informationTippy, {
        content: getTrans(
            'quests-dashboard.completingAtLeast',
            'Completing at least 1 round per day grows your Streak and earns you a Reward. Keeping your Streak continued for 7 days earns you a special Bonus! Skipping a day zeroes your Streak.',
        ),
        placement: 'bottom',
        theme: 'tip-info',
        trigger: 'mouseenter',
        interactive: true,
    });

    const showAuthModal = () => {
        modalStore.setAuthModal(true);
        props.closeTippy();
    };

    const streakData = ref<StreakData[]>([]);

    const setStreakDataValues = (streakDataResponse: ResponseStreakData | null) => {
        if (streakDataResponse) {
            streakData.value = streakDataResponse.data;
            streakCompletedTotal.value = userStore.streakCount || 0;
        }
    };

    const isLoading = ref(true);
    const handleLoad = () => {
        isLoading.value = false;
    };

    const fetchStreakData = async () => {
        try {
            const profileId = userStore.questProfileId;

            if (profileId !== null) {
                await useStreak.fetchStreakData();

                if (useStreak.streakData !== null) {
                    setStreakDataValues(useStreak.streakData);
                    handleLoad();
                }
            } else {
                showToast({
                    message: getTrans('quests-dashboard.streakDataToast', 'You don\'t have a profile ID to get streak xdata'),
                });
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(`Error fetching data: ${error}`);
        }
    };

    const fetchDataWhenUserStoreIsNotLoading = () => {
        const interval = setInterval(async () => {
            if (!userStore.isLoading) {
                clearInterval(interval);
                await fetchStreakData();
            }
        }, 100);
    };

    const streakItemTippyText = ref('');

    const updateTippyText = (streakData) => {
        streakItemTippyText.value = generateTippyText(streakData);
    };

    onMounted(() => {
        if (authStore.isUserLoggedIn) {
            if (useStreak.streakData !== null) {
                setStreakDataValues(useStreak.streakData);
                handleLoad();
            } else {
                fetchDataWhenUserStoreIsNotLoading();
            }
        }
    });
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";
@import "@/js/pages/builder/sass/mixins/scrollbar";

@media (max-width: $md-breakpoint) {
    .streak-card {
        display: flex;
        height: calc(100vh - 100px);
        flex-direction: column;

        .slider-container {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
        }

        .streak-content-card {
            overflow: auto;
            width: 100%;
            padding: 16px;
            background-color: $baseWhite;

            @include custom-scrollbar;
        }
    }
}

.streak-card {
    &__bonus-text {
        color: $baseBlack;
        font-weight: bolder;
        text-align: center;

        @media (max-width: $md-breakpoint) {
            font-size: 18px;
        }

        @media (max-width: $mobile-md-breakpoint) {
            font-size: 16px;
        }
    }
}

.streaks-display {
    display: flex;
    width: 316px;
    flex-wrap: wrap;
    gap: 8px;

    @media (max-width: $md-breakpoint) {
        width: 100%;
    }
}
</style>

<style lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/design-system/fonts";
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";

$streak-card-dark-purple: #370d53;
$streak-card-purple: #893cff;
$streak-card-light-green: $primary600;
$streak-card-border-purple: #561f7b;

.streak-tippy {
    .tippy-box {
        .tippy-content {
            padding: 0;
        }
    }

    .tippy-info {
        .tippy-content {
            padding: 10px;
            font-size: 12px;
            font-weight: 500;
            text-align: left;
        }
    }
}

.streak-card {
    @media (max-width: $md-breakpoint) {
        width: 100%;
        background: $streak-card-dark-purple;

        .streak-content-card {
            border-radius: 0 !important;
        }
    }

    .streak-content-card {
        background-color: $baseWhite;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .text-success {
            color: $jungle-green !important;
        }

        .streak-current-loader {
            display: inline-block;
            width: 11px;
            height: 11px;
            border: 2px solid $streak-card-light-green;
            border-radius: 50%;
            border-bottom-color: transparent;
            margin: 0 6px;
            animation: rotation 1s linear infinite;
        }
    }

    .unauthenticated-card {
        width: 300px;
        min-height: 224px;
        align-items: center;
        justify-content: center;
        margin: 0 10px;
    }

    .streak-fire {
        width: 45px;

        @media (max-width: $md-breakpoint) {
            width: 70px;
        }
    }

    p {
        font-size: 12px;
    }

    .fa-circle-info {
        margin-left: 4px;
        cursor: pointer;
        font-size: 16px;
    }

    div {
        .streak-text {
            font-family: $font-quicksand;
            font-size: 32px;
        }
    }

    .streak-container {
        display: flex;
        min-height: 160px;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;

        @media (max-width: $md-breakpoint-max-width) {
            padding: 8px 0;
            margin: 16px 0;
        }

        .streak-loader {
            display: inline-block;
            width: 35px;
            height: 35px;
            border: 4px solid $streak-card-border-purple;
            border-radius: 50%;
            border-bottom-color: transparent;
            animation: rotation 1s linear infinite;
        }

        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}
</style>
