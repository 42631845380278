<template>
    <div class="back-to-game-footer-mobile d-flex justify-content-between">
        <div class="col-5 d-flex align-items-center">
            <div class="title pt-1">
                🚨<Translate translationKey="quests-dashboard.youreOnActiveMission"
                             fallback="You're on active Mission right now:" />🚨
            </div>
        </div>
        <div :class="{ 'show': isPlayButtonVisible }"
             class="play-btn d-flex align-items-center justify-content-center"
             @click="getUserBackToGame">
            <i class="fa-solid fa-play d-block play-icon ml-1 mt-1" />
        </div>
        <div class="d-flex align-items-center justify-content-center">
            <div>
                <div class="time"
                     :class="{ 'animated': animate, 'text-danger': remainingTime <= 30 }">
                    {{ formatSeconds(remainingTime) }}
                </div>
                <div class="prize">
                    {{ userStore.activeUserRoundData?.quest_round?.quest_mission?.title }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { useUserStore } from '@/js/store/quests/dashboard/UserStore';
    import { closeCurtains } from '../../../game/animations/closeCurtains';
    import { questsRoutes } from '@/js/constants/questsRoutes';
    import { useMobileFooterAnimation } from '../../composables/useMobileFooterAnimation';

    const authStore = useAuthStore();
    const modalStore = useModalStore();
    const userStore = useUserStore();

    const remainingTime = ref(userStore.activeUserRoundData?.remaining_time);
    const animate = ref(false);
    const isPlayButtonVisible = ref(false);

    let timerId = 0;
    let endTime: Date;

    const animateShake = () => {
        animate.value = true;
        setTimeout(() => {
            animate.value = false;
        }, 300);
    };

    watch(
        () => remainingTime.value,
        (newValue) => {
            if (newValue && newValue <= 30) {
                animateShake();
            }
        },
    );

    const getUserBackToGame = () => {
        if (authStore.user?.must_verify_email) {
            modalStore.setForceEmailVerificationModal(true);
        } else {
            closeCurtains(userStore.activeUserRoundData?.quest_round?.content?.url || questsRoutes.MISSION_ROUTE);
        }
    };

    const formatSeconds = (time: number) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - (hours * 3600)) / 60);
        const seconds = time - (hours * 3600) - (minutes * 60);

        return [hours, minutes, seconds].map((v) => `0${v}`.slice(-2)).join(':');
    };

    const startCountdown = () => {
        const startTime = new Date();
        const endTimestamp = startTime.getTime() + remainingTime.value * 1000;
        endTime = new Date(endTimestamp);

        timerId = window.setInterval(() => {
            const now = new Date();
            const remainingTimeMs = endTime.getTime() - now.getTime();

            if (remainingTimeMs > 0) {
                remainingTime.value = Math.floor(remainingTimeMs / 1000);
            } else {
                window.clearInterval(timerId);
                remainingTime.value = 0;

                if (userStore.activeUserRoundData) {
                    userStore.cancelRoundByTimeEnd();
                }

                userStore.fetchActiveUserRoundData();
            }
        }, 1000);
    };

    watch(
        () => userStore.activeUserRoundData,
        (newRoundData) => {
            if (newRoundData?.started_at) {
                remainingTime.value = newRoundData?.remaining_time || 0;
                startCountdown();
            }
        },
        { immediate: true },
    );

    useMobileFooterAnimation(isPlayButtonVisible);
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/missions-partials/z-index";
@import "@/sass/design-system/fonts";
@import "@/sass/design-system/variables";
@import "@/js/quests/global/sass/mixins/animations";

$mobile-footer-height: 60px;
$back-to-game-footer-height: 40px;

.back-to-game-footer-mobile {
    position: fixed;
    z-index: $back-to-game-footer-z-index;
    bottom: $mobile-footer-height;
    left: 0;
    width: 100%;
    height: $back-to-game-footer-height;
    padding: 0 8px;
    border-top: 1px solid $dark-theme-border;
    border-bottom: 1px solid $dark-theme-border;
    background-color: $brand950;

    .play-btn {
        position: absolute;
        top: 100px;
        left: 50%;
        width: 80px;
        height: 80px;
        border: 1px solid $warning500;
        border-radius: 50%;
        background-color: $warning500;
        box-shadow: 0 0 20px $warning600, 0 0 30px $warning600;
        color: $warning900;
        cursor: pointer;
        transform: translateX(-50%) translateY(0);
        transition: top 0s ease-in-out, transform 0.5s ease-in-out;

        @include glow-animation(2s, $warning600);

        &.show {
            top: $back-to-game-footer-height - 15px !important;
            transform: translate(-50%, -50%);
        }

        .play-icon {
            font-size: 27px;
        }
    }

    .title {
        color: #f2c82c;
        font-family: $font-quicksand;
        font-size: 8px;
        text-align: center;
        text-transform: uppercase;
    }

    .prize {
        max-width: 131px;
        color: $baseWhite;
        font-size: 8px;
        font-weight: 500;
        line-height: 1;
    }

    @media (max-width: 374px) {
        .play-btn {
            width: 4rem;
            height: 4rem;

            .play-icon {
                font-size: 24px;
            }
        }
        .prize {
            max-width: 7.4rem;
        }
    }

    .text-danger {
        color: #fe4242;
    }

    .time {
        color: $baseWhite;
        font-family: $font-quicksand;
        font-size: 13px;
        font-weight: 700;
        line-height: 1;
    }

    @keyframes shake {
        0% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(10px);
        }
        50% {
            transform: translateX(-10px);
        }
        75% {
            transform: translateX(10px);
        }
        100% {
            transform: translateX(0);
        }
    }

    .animated {
        animation: shake 0.3s linear;
    }
}
</style>
