<template>
    <div class="tablet-header d-flex justify-content-end align-items-center p-3 pb-0">
        <div class="d-flex justify-content-center align-items-center">
            <MobileScreenIcon />
            <DesktopNotifications />
            <DesktopStreakTippy />
            <DashboardUserStats class="user-stats-component mr-3 ml-3" />
            <div ref="menuCardToggleRef"
                 class="d-flex justify-content-center align-center py-3"
                 @click="navigationStore.showMenuCard = !navigationStore.showMenuCard">
                <div class="menu-toggle"
                     :class="{ 'active': navigationStore.showMenuCard }" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import DashboardUserStats from '../DashboardUserStats.vue';
    import { useNavigationStore } from '@/js/store/quests/dashboard/NavigationStore';
    import DesktopStreakTippy from '../desktop/DesktopStreakTippy.vue';
    import DesktopNotifications from '../desktop/DesktopNotifications.vue';
    import MobileScreenIcon from '../../../../global/header/components/MobileScreenIcon.vue';

    const navigationStore = useNavigationStore();
    const emit = defineEmits(['set-menu-card-toggle-ref']);

    const menuCardToggleRef = ref(null);

    onMounted(() => {
        emit('set-menu-card-toggle-ref', menuCardToggleRef);
    });
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/variables";
@import "@/sass/partials/breakpoints";

@media (max-width: $md-breakpoint-max-width) {
    .padding-on-mobile {
        padding: 0;
    }
}

.slide-left {
    transform: translateX(-75px);
    transition: all 0.5s ease;
}

.slide-right {
    transform: translateX(0);
    transition: all 0.3s ease-out;
}

.user-stats-component {
    min-width: 217px;
}

.menu-toggle {
    width: 25px;
    height: 2px;
    border-radius: 4px;
    background-color: $gray300;
    transform: initial;
    transition: transform 300ms ease 0s, background-color 0s cubic-bezier(0.6, 0, 0.15, 1) 0s;

    &::before {
        position: absolute;
        display: block;
        width: 25px;
        height: 2px;
        border-radius: 4px;
        background-color: $gray300;
        content: "";
        transform: translateY(-8px);
        transition: transform 300ms ease 0s, background-color 0s cubic-bezier(0.6, 0, 0.15, 1) 0s;
    }

    &::after {
        position: absolute;
        display: block;
        width: 25px;
        height: 2px;
        border-radius: 4px;
        background-color: $gray300;
        content: "";
        transform: translateY(8px) rotate(0deg);
        transition: transform 300ms ease 0s, background-color 0s cubic-bezier(0.6, 0, 0.15, 1) 0s;
    }

    &.active {
        transform: rotate(45deg);

        &::before {
            transform: translateY(0);
        }

        &::after {
            transform: translateY(0) rotate(-90deg);
        }
    }
}
</style>
