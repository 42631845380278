<template>
    <div class="streak-content-container text-white">
        <div class="close-button"
             @click="navigationStore.showMobileStreak = false">
            &times;
        </div>
        <div class="streak-card-container">
            <StreakCard />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useNavigationStore } from '@/js/store/quests/dashboard/NavigationStore';
    import StreakCard from '../streak/StreakCard.vue';
    import { useModalActive } from '@/js/composables/useModalActive';

    const navigationStore = useNavigationStore();

    useModalActive();
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/missions-partials/z-index";
@import "@/sass/design-system/variables";

.streak-content-container {
    position: fixed;
    z-index: $mobile-notifications-container-z-index;
    top: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    height: 100vh;
    background: $baseWhite;

    .close-button {
        display: flex;
        height: 40px;
        padding-left: 16px;
        background: $tippy-mobile-background;
        color: $light-grey-blue;
        cursor: pointer;
        font-size: 40px;
        transition: color 200ms ease;

        &:hover {
            color: $baseWhite;
        }
    }

    .streak-card-container {
        display: flex;
        justify-content: center;

        p {
            font-size: 12px;
        }
    }
}
</style>
