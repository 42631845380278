import { getValueFromLocalStorage, setValueToLocalStorage } from '@/js/quests/game/helpers/localStorageHelper';
import { deleteUrlParam } from '@/js/helpers/urlUtils';

const REFERRAL_QUERY_PARAM_KEY = 'r';
const REFERRAL_LOCAL_STORAGE_KEY = 'ref';

export const isReferralCodeInUrl = (): boolean => {
    const queryParams = new URLSearchParams(window.location.search);

    return queryParams.has(REFERRAL_QUERY_PARAM_KEY);
};

export const storeReferralCode = (): void => {
    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.has(REFERRAL_QUERY_PARAM_KEY)) {
        const referralCode = queryParams.get(REFERRAL_QUERY_PARAM_KEY);

        setValueToLocalStorage(REFERRAL_LOCAL_STORAGE_KEY, referralCode);
        deleteUrlParam(REFERRAL_QUERY_PARAM_KEY, queryParams);
    }
};

export const isReferralCodeStored = (): boolean => !!getValueFromLocalStorage(REFERRAL_LOCAL_STORAGE_KEY);
