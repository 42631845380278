<template>
    <div>
        <div class="notifications-desktop-tippy-content">
            <div class="notification-bell-content">
                <div class="title">
                    <Translate translationKey="quests-dashboard.notifications"
                               fallback="Notifications" />
                </div>
                <ScrollShadowObserver maxHeight="250px">
                    <template #content>
                        <div>
                            <PromoNotificationRow @click="props.closeTippy" />
                            <div v-for="notification in notifications"
                                 :key="notification?.title">
                                <NotificationRow :notification="notification"
                                                 @click="props.closeTippy" />
                            </div>
                            <NotificationsFooter :loadMoreNotifications="loadMoreNotifications" />
                        </div>
                    </template>
                    <template #shadow-component="shadowComponentProps">
                        <ScrollIndicator :show="shadowComponentProps.show" />
                    </template>
                </ScrollShadowObserver>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
    } from 'vue';
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { useAuthStore } from '@/js/store/auth/AuthStore';
    import { loggedOutNotifications, useNotificationStore } from '@/js/store/quests/dashboard/NotificationStore';
    import NotificationRow from './NotificationRow.vue';
    import ScrollShadowObserver from '../../../../../components/ScrollShadowObserver/ScrollShadowObserver.vue';
    import NotificationsFooter from './NotificationsFooter.vue';
    import ScrollIndicator from '../../../../../components/ScrollShadowObserver/ScrollIndicator.vue';
    import PromoNotificationRow
        from '@/js/quests/dashboard/modals/notifications/header-notifications/PromoNotificationRow.vue';

    const authStore = useAuthStore();
    const notificationStore = useNotificationStore();

    interface NotificationsMobileModalProps {
        loadMoreNotifications: () => void;
        closeTippy?: () => void;
    }

    const props = defineProps<NotificationsMobileModalProps>();

    const storeNotifications = computed(() => notificationStore.notificationData?.data?.map((notification) => ({
        url: notification?.data.url,
        title: notification?.data.title,
        subtitle: notification?.data.subtitle,
        image_path: notification?.data.image_path,
        read_at: notification.read_at,
        type: notification?.type,
        guild_slug: notification?.data.guild_slug,
        mission_slug: notification?.data.mission_slug,
    })));

    const modalStore = useModalStore();
    const loggedOutNotificationsEnhanced = computed(() => loggedOutNotifications.map((x) => ({ ...x, onClick: x.url ? null : () => modalStore.setAuthModal(true) })));

    const notifications = computed(() => (authStore.isUserLoggedIn ? storeNotifications.value : loggedOutNotificationsEnhanced.value));
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/design-system/variables";

.notifications-desktop-tippy-content {
    min-width: 350px;

    .title {
        padding: 8px;
        border-bottom: 1px solid $purple-active;
        margin: 0;
        color: $baseWhite;
    }

    .notification-bell-content {
        width: 100%;
    }
}
</style>
